import axios from "axios";
import { getLocalData, getSessionData } from "utils/storage";

const api_url =
  process.env.REACT_APP_API_URL || "https://apistaging.noteszen.com/v1";
// const api_url = "https://apistaging.noteszen.com/v1";

let defaultHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
};

let authHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PUT",
  Authorization: `Bearer ${getLocalData("@nz__l")}`,
};

let authHeadersAdmin = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PUT",
  Authorization: `Bearer ${getSessionData("@nz__l")}`,
};

// #############
// auth
// #############
export const signUp = ({ firstName, lastName, email, password }) => {
  return axios.post(`${api_url}/provider/auth`, {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    uId: "",
    type: "email",
  });
};

export const loginAPI = ({ email, password }) => {
  return axios.post(`${api_url}/provider/login`, {
    email,
    password,
    uId: "",
    type: "email",
  });
};

export const verifyOtpAPI = ({ email, otp }) => {
  return axios.post(`${api_url}/provider/verify_otp`, {
    email,
    otp,
  });
};

export const socialLogin = ({ firstName, lastName, email, uId, type }) => {
  return axios.post(`${api_url}/provider/auth`, {
    first_name: firstName,
    last_name: lastName,
    email,
    password: "",
    uId,
    type,
  });
};

export const changePasswordAPI = ({ oldPassword, newPassword }) => {
  return axios.put(
    `${api_url}/provider/updatePassword`,
    {
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
    { headers: authHeaders }
  );
};

export const recoverPasswordAPI = ({ email }) => {
  return axios.post(
    `${api_url}/provider/resetPassword`,
    {
      email: email,
    },
    { headers: authHeaders }
  );
};

export const resetPasswordAPI = ({ email, password, otp }) => {
  return axios.post(
    `${api_url}/provider/changePassword`,
    {
      email: email,
      password: password,
      otp: otp,
    },
    { headers: authHeaders }
  );
};

export const updateUserInfoAPI = ({
  first_name,
  last_name,
  phone_number,
  organization_name,
  physicians_count,
  organization_address,
  electronic_medical_record,
  how_did_you_learn,
  special_request,
  practice_name,
}) => {
  return axios.put(
    `${api_url}/provider/updateUserInfo`,
    {
      first_name,
      last_name,
      phone_number,
      organization_name,
      physicians_count,
      organization_address,
      electronic_medical_record,
      how_did_you_learn,
      special_request,
      practice_name,
    },
    { headers: authHeaders }
  );
};

export const updateIntroStatus = () => {
  return axios.put(`${api_url}/provider/introDone`, {}, { headers: authHeaders });
};

// #############
// patients
// #############
export const getPatientsAPI = ({ date, name }) => {
  return axios.get(
    `${api_url}/patient/?date=${date || ""}&search=${name || ""}`,
    {
      headers: authHeaders,
    }
  );
};

export const getSinglePatientAPI = ({ id }) => {
  return axios.get(`${api_url}/patient/?id=${id}`, {
    headers: authHeaders,
  });
};

// #############
// patient's Visits | Details
// #############
export const getPatientsVisitsAPI = ({ id }) => {
  return axios.get(`${api_url}/encounter/patientEncounters?patientId=${id}`, {
    headers: authHeaders,
  });
};

// #############
// subscriptions | Card details
// #############
export const getSubscription = () => {
  return axios.get(`${api_url}/provider/tierStatus`, { headers: authHeaders });
};

export const cancelSubscription = () => {
  return axios.post(
    `${api_url}/provider/cancelSubscription`,
    {},
    {
      headers: authHeaders,
    }
  );
};

export const getCardDetailsAPI = () => {
  return axios.get(`${api_url}/provider/card`, { headers: authHeaders });
};

export const updateCardDetailsAPI = ({ cardToken }) => {
  return axios.post(
    `${api_url}/provider/card`,
    { card_token: cardToken },
    { headers: authHeaders }
  );
};

export const deleteCardDetailsAPI = ({ cardToken }) => {
  return axios.delete(`${api_url}/provider/card/${cardToken}`, {
    headers: authHeaders,
  });
};

export const getPendingNotesAPI = () => {
  return axios.get(`${api_url}/encounter/uncompleteCount`, {
    headers: authHeaders,
  });
};

// #############
// Template
// #############
export const getTemplatesApi = () => {
  return axios.get(`${api_url}/template/`, { headers: authHeaders });
};

// #############
// EMR
// #############
export const generateEmrAPI = ({ templateId, transcription }) => {
  return axios.post(
    `${api_url}/template/get_emr`,
    { template_id: templateId, transcription },
    { headers: authHeaders }
  );
};

// #############
// Encounter | Visit
// #############
export const getAllEncountersAPI = ({ startDate, endDate }) => {
  return axios.get(
    `${api_url}/encounter/?start_date=${startDate}&end_date=${endDate}`,
    { headers: authHeaders }
  );
};

export const getSingleEncounterAPI = ({ id }) => {
  return axios.get(`${api_url}/encounter/?encounterId=${id}`, {
    headers: authHeaders,
  });
};

export const addEncounterAPI = ({ encounter }) => {
  return axios.post(
    `${api_url}/encounter/`,
    { ...encounter },
    { headers: authHeaders }
  );
};

export const updateSingleEncounterAPI = ({ encounter, encounterId }) => {
  return axios.put(
    `${api_url}/encounter/`,
    { encounter: { ...encounter }, encounterId },
    { headers: authHeaders }
  );
};

// #################
// #################
// Admin API
// #################
// #################

// #############
// Template API
// #############
export const getAdminTemplatesAPI = () => {
  return axios.get(`${api_url}/template/`, {
    headers: authHeadersAdmin,
  });
};

export const getSingleTemplateAPI = ({ id }) => {
  return axios.get(`${api_url}/template/?id=${id}`, {
    headers: authHeadersAdmin,
  });
};

export const addTemplateAPI = (template) => {
  return axios.post(
    `${api_url}/template/`,
    { ...template },
    { headers: authHeadersAdmin }
  );
};

export const updateTemplateAPI = (template) => {
  return axios.put(
    `${api_url}/template/`,
    { ...template },
    { headers: authHeadersAdmin }
  );
};

export const deleteTemplateAPI = (templateId) => {
  return axios.delete(`${api_url}/template/${templateId}`, {
    headers: authHeadersAdmin,
  });
};

// #############
// Template Type API
// #############
export const getTemplateTypesAPI = () => {
  return axios.get(`${api_url}/template/type`, {
    headers: authHeadersAdmin,
  });
};

export const addTemplateTypeAPI = (templateType) => {
  return axios.post(
    `${api_url}/template/type`,
    { ...templateType },
    { headers: authHeadersAdmin }
  );
};

export const updateTemplateTypeAPI = (templateType) => {
  return axios.put(
    `${api_url}/template/type`,
    { ...templateType },
    { headers: authHeadersAdmin }
  );
};

export const deleteTemplateTypeAPI = (typeId) => {
  return axios.delete(`${api_url}/template/type/${typeId}`, {
    headers: authHeadersAdmin,
  });
};

// #############
// group API
// #############
export const getGroupsAPI = () => {
  return axios.get(`${api_url}/group/`, {
    headers: authHeadersAdmin,
  });
};

export const addGroupAPI = (group) => {
  return axios.post(
    `${api_url}/group/`,
    { ...group },
    { headers: authHeadersAdmin }
  );
};

export const updateGroupAPI = (group) => {
  return axios.put(
    `${api_url}/group/`,
    { ...group },
    { headers: authHeadersAdmin }
  );
};

export const deleteGroupAPI = (groupId) => {
  return axios.delete(`${api_url}/group/${groupId}`, {
    headers: authHeadersAdmin,
  });
};
