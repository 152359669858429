import { ErrorOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  addTemplateTypeAPI,
  deleteTemplateTypeAPI,
  getTemplateTypesAPI,
  updateTemplateTypeAPI,
} from "apis";
import Loader from "components/loader";
import { COLORS } from "constants/colors";
import { useAuthContext } from "contexts/authContext";
import AdminLayout from "fragments/admin/layout";
import RouteGuardAdmin from "hoc/routeGuardAdmin";
import { useEffect, useState } from "react";
import { BiError, BiSolidError } from "react-icons/bi";
import { FaEllipsisH } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function TemplateType() {
  const [templateTypes, setTemplatetypes] = useState();
  const { admin, adminToken, handleSessionExpired } = useAuthContext();
  const [anchorElArray, setAnchorElArray] = useState([]);
  const [typeToUpdate, setTypeToUpdate] = useState(null);
  const [addType, setAddType] = useState(null);
  const [typeToDelete, setTypeToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTemplateTypes = async () => {
    await getTemplateTypesAPI()
      .then((res) => {
        // console.log(res);
        setTemplatetypes(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const handleUpdateType = async () => {
    setLoading(true);
    await updateTemplateTypeAPI({
      type: {
        title: typeToUpdate?.title,
      },
      type_id: typeToUpdate?.id,
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Updating Template Type.");
        } else {
          handleUpdateTypeInState();
          handleClose();
          toast?.success("Successfully Update Template Type.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleUpdateTypeInState = () => {
    setTemplatetypes((prevArray) => {
      const index = prevArray.findIndex((obj) => obj.id === typeToUpdate.id);

      if (index !== -1) {
        // Update the object at the found index
        const newArray = [...prevArray];
        newArray[index] = { ...newArray[index], ...typeToUpdate };
        return newArray;
      }

      return prevArray;
    });
  };

  const handleAddType = async () => {
    setLoading(true);
    await addTemplateTypeAPI({
      title: addType?.title,
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Adding Template Type.");
        } else {
          setTemplatetypes([...templateTypes, res?.data?.result]);
          setAddType(null);
          toast?.success("Successfully Added Template Type.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleDeleteType = async () => {
    setLoading(true);
    await deleteTemplateTypeAPI(typeToDelete?.id)
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Deleting Template Type.");
        } else {
          handleDeleteTypeInState();
          setTypeToDelete(null);
          toast?.success("Successfully Deleted Template Type.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleDeleteTypeInState = () => {
    setTemplatetypes((prevArray) =>
      prevArray.filter((obj) => obj.id !== typeToDelete?.id)
    );
  };

  const handleMenuClick = (event, objectId) => {
    setAnchorElArray((prevArray) => [
      ...prevArray,
      { id: objectId, anchorEl: event.currentTarget },
    ]);
    setTypeToUpdate(null);
  };

  const handleClose = (objectId) => {
    setAnchorElArray((prevArray) =>
      prevArray.filter((item) => item.id !== objectId)
    );
    setTypeToUpdate(null);
  };

  const handleMenuItemClick = (action, objectId, template) => {
    handleClose(objectId);
    if (action == "Edit") {
      setTypeToUpdate(template);
    } else if (action == "Delete") {
      setTypeToDelete(template);
    }
  };

  useEffect(() => {
    getTemplateTypes();
  }, []);

  // console.log(openUpdateModal);

  return (
    <RouteGuardAdmin>
      <AdminLayout>
        <div className="m-5">
          <div className="flex justify-between">
            <h1 className="text-2xl">Template Types</h1>

            <Button onClick={() => setAddType({})}>Add</Button>
          </div>

          <div className="h-400 mt-5">
            {templateTypes ? (
              <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                {templateTypes.map((template, i) => (
                  <li
                    key={template.id}
                    className="col-span-1 flex rounded-lg shadow-sm">
                    <div className="flex flex-1 items-center justify-between rounded-lg truncate rounded-r-md border border-gray-200 bg-white">
                      <div className="flex-1 truncate px-4 py-2 text-sm">
                        <p className="font-medium text-gray-900 hover:text-gray-600">
                          {template.title}
                        </p>
                        <p className="text-gray-500">
                          Template Type
                        </p>
                      </div>
                      <div className="flex-shrink-0 pr-2">
                        <IconButton
                          aria-label="more"
                          aria-controls={`menu-${i}`}
                          aria-haspopup="true"
                          onClick={(event) => handleMenuClick(event, i)}
                          sx={{ color: COLORS?.grottoBlue }}>
                          <FaEllipsisH className="h-5 w-5" aria-hidden="true" />
                        </IconButton>
                        <Menu
                          id={`menu-${i}`}
                          anchorEl={
                            anchorElArray.find((item) => item.id === i)
                              ?.anchorEl
                          }
                          open={Boolean(
                            anchorElArray.find((item) => item.id === i)
                          )}
                          onClose={() => handleClose(i)}>
                          <MenuItem
                            onClick={() =>
                              handleMenuItemClick("Edit", i, template)
                            }>
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleMenuItemClick("Delete", i, template)
                            }>
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="h-[50vh]">
                <Loader />
              </div>
            )}
          </div>

          {/* To Update Type */}
          <Dialog
            open={Boolean(typeToUpdate)}
            onClose={() => setTypeToUpdate(null)}>
            <DialogTitle>Update Template Type</DialogTitle>
            <DialogContent>
              <div className="mt-4">
                <TextField
                  value={typeToUpdate?.title}
                  sx={{ minWidth: "300px" }}
                  autoFocus
                  label="Template Type Title"
                  variant="outlined"
                  onChange={(e) =>
                    setTypeToUpdate({
                      ...typeToUpdate,
                      title: e?.target?.value,
                    })
                  }
                />
              </div>
            </DialogContent>
            <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleUpdateType()}>
                Update
              </LoadingButton>
            </DialogActions>
          </Dialog>

          {/* To Add New Type */}
          <Dialog open={Boolean(addType)} onClose={() => setAddType(null)}>
            <DialogTitle>Add Template Type</DialogTitle>
            <DialogContent>
              <div className="mt-4">
                <TextField
                  value={typeToUpdate?.title}
                  sx={{ minWidth: "300px" }}
                  autoFocus
                  label="Template Type Title"
                  variant="outlined"
                  onChange={(e) =>
                    setAddType({
                      ...addType,
                      title: e?.target?.value,
                    })
                  }
                />
              </div>
            </DialogContent>
            <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
              <Button variant="outlined" onClick={() => setAddType(null)}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleAddType()}>
                Add
              </LoadingButton>
            </DialogActions>
          </Dialog>

          {/* To Delete Type */}
          <Dialog
            open={Boolean(typeToDelete)}
            onClose={() => setTypeToDelete(null)}>
            <DialogTitle>Delete Template Type</DialogTitle>
            <DialogContent>
              <div className="flex gap-2 items-center">
                <BiError className="text-red-600 text-3xl" />
                Confirm to Delete Template Type{" "}
                <span className="text-grottoBlue font-semibold">
                  "{typeToDelete?.title}"
                </span>{" "}
                ?
              </div>
            </DialogContent>
            <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
              <Button variant="outlined" onClick={() => setTypeToDelete(null)}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleDeleteType()}>
                Delete
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </div>
      </AdminLayout>
    </RouteGuardAdmin>
  );
}
