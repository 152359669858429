import { getAllEncountersAPI, getPendingNotesAPI } from "apis";
import { toast } from "react-toastify";
import { removeLocalData } from "utils/storage";

const { createSlice } = require("@reduxjs/toolkit");

const encounterSlice = createSlice({
  name: "encounters",
  initialState: {
    encounters: null,
    pendingNotesCount: null,
  },
  reducers: {
    setEncounters(state, action) {
      state.encounters = action.payload;
    },
    setPendingNotesCount(state, action) {
      state.pendingNotesCount = action.payload;
    },
    deleteEncounter(state, action) {
      state.encounters = state.encounters.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateEncounter(state, action) {
      const index = state.encounters.findIndex(
        (item) => item.id === action.payload.id
      );
      state.encounters[index] = {
        ...state.encounters[index],
        ...action.payload,
      };
    },
    addEcounter(state, action) {
      state.encounters.push(action.payload);
    },
  },
});

export const {
  setEncounters,
  deleteEncounter,
  setPendingNotesCount,
  addEcounter,
  updateEncounter,
} = encounterSlice.actions;
export default encounterSlice.reducer;

// thunks
export function fetchEncounters({ startDate, endDate }) {
  return async function fetchEncountersThunk(dispatch, getState) {
    dispatch(setEncounters(null));
    await getAllEncountersAPI({ startDate, endDate })
      .then((res) => {
        // console.log(res);
        // const _encounters = res.data.result.map((event) => ({
        //   title: `${event.patient.first_name} ${event.patient.last_name} | ${event.patient.dob}`,
        //   start: new Date(event.entry_date),
        //   end: new Date(event.entry_date),
        //   ...event,
        // }));
        dispatch(setEncounters({ encounters: res.data.result }));
      })
      .catch((err) => {
        dispatch(setEncounters(null));
        if (err?.response?.status === 401) {
          toast.error(
            err?.response?.message || "Session Expired. Kindly login again"
          );
          removeLocalData("@nz__l");
          removeLocalData("@nz__user");
          window?.location?.replace("/session-expired");
        }
      });
  };
}

export function fetchPendingNotes() {
  return async function fetchPendingNotesCountThunk(dispatch, getState) {
    await getPendingNotesAPI()
      .then((res) => {
        // console.log(res);
        dispatch(setPendingNotesCount(res?.data?.result?.count));
      })
      .catch((err) => {
        dispatch(setPendingNotesCount(null));
        if (err?.response?.status === 401) {
          toast.error(
            err?.response?.message || "Session Expired. Kindly login again"
          );
          removeLocalData("@nz__l");
          removeLocalData("@nz__user");
          // window?.location?.replace("/session-expired");
        }
      });
  };
}
