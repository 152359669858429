import { Popover, Transition } from "@headlessui/react";
import { Details, NewReleases, ViewAgenda } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getPatientsAPI } from "apis";
import clsx from "clsx";
import { NavLink } from "components/common/NavLink";
import { Container } from "components/common/container";
import { DropDown } from "components/common/dropdown";
import Loader from "components/loader";
import { useAuthContext } from "contexts/authContext";
import { Fragment, useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { formateDate } from "utils";

const publicLinks = [
  { name: "Home", href: "/" },
  // { name: "Early Access", href: "/trial" },
  { name: "Pricing", href: "/pricing" },
  { name: "Support", href: "/support" },
  { name: "Contact Us", href: "/contact" },
];
const privateLinks = [
  { name: "Visit", href: "/visit" },
  { name: "Schedule", href: "/schedule" },
  { name: "Support", href: "/support" },
  { name: "Pricing", href: "/pricing" },
];

const useStyles = makeStyles({
  dialogPaper: {
    margin: 20,
    // top: "-20%", // Adjust this value to position at the top
  },
});

export function Header() {
  const classes = useStyles();
  const { user, token, handleLogout, handleSessionExpired } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState(null);
  const [searching, setSearching] = useState(false);

  const [inputValue, setInputValue] = useState({});

  const handleSearch = useDebouncedCallback(async () => {
    await getPatientsAPI({ date: inputValue?.date, name: inputValue?.name })
      .then((res) => {
        // console.log(res);
        setResults(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setSearching(false);
  }, 500);

  const handleChange = (field, value) => {
    setSearching(true);
    setInputValue({ ...inputValue, [field]: value });
  };

  useEffect(() => {
    if (token) {
      handleSearch();
    }
  }, [inputValue?.name, inputValue?.dob]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();

        // console.log("Ctrl+K pressed!");
        setIsOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // console.log(inputValue);
  // console.log(results);

  return (
    <header className="py-10">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-grottoBlue/5 [mask-image:radial-gradient(100%_100%_at_top_right,babyBlue,transparent)]"
        aria-hidden="true">
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-babyBlue/5">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <img src="/media/logo.png" alt="" className="w-[200px]" />
            </Link>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden md:flex items-center md:gap-2">
              {token && (
                <div
                  onClick={() => setIsOpen(true)}
                  className="text-sm flex gap-2 cursor-pointer px-2 py-1 items-center rounded-md text-slate-700 bg-slate-200 hover:text-slate-900">
                  <BsSearch />
                  ctrl+k
                </div>
              )}
              {!token ? (
                <>
                  {publicLinks.map((item) => (
                    <NavLink key={item.name} to={item.href}>
                      {item.name}
                    </NavLink>
                  ))}
                </>
              ) : (
                <>
                  {token &&
                    privateLinks.map((item) => (
                      <NavLink key={item.name} to={item.href}>
                        {item.name}
                      </NavLink>
                    ))}
                </>
              )}
            </div>

            {token ? (
              <div className="hidden md:block">
                <DropDown
                  bodyClassName="max-w-[200px] mt-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  label={
                    <Button className="bg-blue text-white max-w-[200px] whitespace-nowrap  text-ellipsis">
                      Welcome {user?.firstName}
                    </Button>
                  }>
                  <div className="w-full flex flex-col">
                    <div className="flex items-center gap-2 hover:bg-gray-200 cursor-pointer rounded-md p-2">
                      <Avatar src={user?.photoURL} />
                      <p className="text-lg font-bold">{user?.firstName}</p>
                    </div>
                    <hr className="m-2 border-slate-300/40" />
                    <Link to="/settings?tab=account" className="mb-2">
                      <div className="flex items-center gap-2 hover:bg-gray-200 cursor-pointer rounded-md p-2">
                        <FiSettings />
                        Settings
                      </div>
                    </Link>
                    <Button
                      onClick={() => handleLogout()}
                      className="bg-blue text-white w-full z-[200]">
                      Log out
                    </Button>
                  </div>
                </DropDown>
              </div>
            ) : (
              <Link to="/login">
                <Button className="bg-blue text-white">Sign in</Button>
              </Link>
            )}

            <div className="-mr-1 md:hidden">
              <Popover>
                <Popover.Button
                  className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
                  aria-label="Toggle Navigation">
                  {({ open }) => <MobileNavIcon open={open} />}
                </Popover.Button>
                <Transition.Root>
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <Popover.Panel
                      as="div"
                      className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5">
                      {!token ? (
                        <>
                          {publicLinks.map((item) => (
                            <MobileNavLink key={item.name} to={item.href}>
                              {item.name}
                            </MobileNavLink>
                          ))}
                        </>
                      ) : (
                        <>
                          {token &&
                            privateLinks.map((item) => (
                              <MobileNavLink key={item.name} to={item.href}>
                                {item.name}
                              </MobileNavLink>
                            ))}
                        </>
                      )}
                      <hr className="m-2 border-slate-300/40" />
                      {!token ? (
                        <>
                          <Link to="/login">
                            <Button className="w-full bg-blue text-white">
                              Log in
                            </Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <div className="flex mb-4 items-center gap-2 hover:bg-grottoBlue/10 cursor-pointer rounded-md p-2">
                            <Avatar className="h-8 w-8" />
                            <p className="text-lg font-bold">
                              {user?.firstName}
                            </p>
                          </div>
                          <Link to="/settings?tab=account" className="mb-2">
                            <div className="flex items-center gap-2 hover:bg-gray-200 cursor-pointer rounded-md p-2">
                              <FiSettings />
                              Settings
                            </div>
                          </Link>
                          <Button
                            onClick={() => handleLogout()}
                            className="bg-blue text-white w-full z-[200]">
                            Log out
                          </Button>
                        </>
                      )}
                    </Popover.Panel>
                  </Transition.Child>
                </Transition.Root>
              </Popover>
            </div>
          </div>
        </nav>
      </Container>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <DialogTitle>
          <TextField
            label="Search by Patient's name"
            variant="outlined"
            sx={{ marginY: "5px" }}
            value={inputValue?.name}
            onChange={(event) => handleChange("name", event.target.value)}
          />
          <TextField
            type="date"
            label="Search by Patient's date of birth"
            variant="outlined"
            sx={{ marginY: "5px" }}
            value={inputValue?.date}
            onChange={(event) => handleChange("date", event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className="w-[300px] sm:w-[500px]">
            {!searching ? (
              <>
                {results?.length > 0 ? (
                  <>
                    {results?.map((encounter) => (
                      <div
                        className={`px-4 py-2 flex my-2 hover:bg-slate-200 w-full rounded-lg cursor-pointer`}>
                        <div className="w-full text-sm flex flex-col gap-1">
                          <div className="grid grid-cols-2">
                            <p className="font-semibold">Patient Name</p>
                            <p>
                              {encounter?.first_name} {encounter?.last_name}
                            </p>
                          </div>
                          <div className="grid grid-cols-2">
                            <p className="font-semibold">DOB</p>
                            <p>{formateDate(encounter?.patient?.dob)}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Link
                            to={`/visit?patientId=${encounter?.id}`}
                            key={encounter?.id}
                            onClick={() => setIsOpen(false)}>
                            <Tooltip title="New Appointment">
                              <IconButton>
                                <NewReleases />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link
                            to={`/patients/${encounter?.id}`}
                            key={encounter?.id}
                            onClick={() => setIsOpen(false)}>
                            <Tooltip title="View Details">
                              <IconButton>
                                <Details />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                    <BiError style={{ fontSize: "2rem", opacity: "0.4" }} />
                    No Search result found.
                  </div>
                )}
              </>
            ) : (
              <>
                <Loader />
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </header>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round">
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          open && "scale-90 opacity-0"
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !open && "scale-90 opacity-0"
        )}
      />
    </svg>
  );
}

function MobileNavLink({ to, children }) {
  return (
    <Popover.Button as={Link} to={to} className="block w-full p-2">
      {children}
    </Popover.Button>
  );
}
