import { Button } from "@mui/material";
import { getAllEncountersAPI } from "apis";
import { Container } from "components/common/container";
import ErrorMsg from "components/common/error";
import Loader from "components/loader";
import { useAuthContext } from "contexts/authContext";
import dayjs from "dayjs";
import Layout from "fragments/layout/layout";
import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { fetchEncounters, setEncounters } from "store/encounterSlice";

const localizer = dayjsLocalizer(dayjs);

export function Schedule() {
  // const navigate = useNavigate();
  const currentDate = new Date();
  // const dispatch = useDispatch();
  const { handleSessionExpired } = useAuthContext();
  const [range, setRange] = useState({
    startDate: moment(currentDate).startOf("month").format("YYYY-MM-DD"),
    endDate: moment(currentDate)
      .endOf("month")
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
  });
  const [encounters, setEncounters] = useState();
  const [error, setError] = useState(false);

  // const { encounters } = useSelector((state) => state.encounters);

  const handleNavigate = (date) => {
    const startOfMonth = moment(date).startOf("month");
    const endOfMonth = moment(date).endOf("month").subtract(1, "day");

    setRange({
      startDate: startOfMonth.format("YYYY-MM-DD"),
      endDate: endOfMonth.format("YYYY-MM-DD"),
    });
  };

  const getMonthlySchedule = async () => {
    getAllEncountersAPI({ startDate: range.startDate, endDate: range.endDate })
      .then((res) => {
        // console.log(res);
        const _encounters = res.data.result.map((event) => ({
          title: `${event.patient.first_name} ${event.patient.last_name} | ${event.patient.dob}`,
          start: new Date(event.entry_date),
          end: new Date(event.entry_date),
          ...event,
        }));
        // dispatch(setEncounters({ encounters: _encounters }));
        setEncounters(_encounters);
      })
      .catch((err) => {
        console.log(err);
        toast?.error(err?.response?.message || "Error fetching Encounters");
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
        setError("getMonthlySchedule");
      });
  };

  useEffect(() => {
    getMonthlySchedule();
    // dispatch(
    //   fetchEncounters({ startDate: range?.startDate, endDate: range?.endDate })
    // );
  }, [range]);

  return (
    <Layout title="Schedule">
      <Container>
        <div className="w-full h-full my-12">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl text-grottoBlue font-semibold my-12">
              Schedule
            </h1>
            <Link to="/visit">
              {/* <IconButton sx={{ color: COLORS.grottoBlue }}>
                    <IoIosAddCircle className="text-3xl text-grottoBlue" />
                  </IconButton> */}
              <Button variant="contained">Add Visit</Button>
            </Link>
          </div>
          {error !== "getMonthlySchedule" ? (
            <>
              {encounters ? (
                <Calendar
                  localizer={localizer}
                  events={encounters}
                  // showAllEvents
                  defaultView="day"
                  views={["month", "week", "day"]}
                  onNavigate={() => handleNavigate()}
                  popup
                  startAccessor="start"
                  defaultDate={dayjs(new Date())}
                  endAccessor="end"
                  onSelectEvent={(event) =>
                    window.open(`/visit/${event?.id}`, "_blank")
                  }
                  // eventPropGetter={(event) => ({
                  //   children: generateEventLink(event),
                  // })}
                  style={{ height: 600 }}
                />
              ) : (
                <Loader />
              )}
            </>
          ) : (
            <div>
              <ErrorMsg text="No Visit found. Something went wrong." />
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
}
