import CategoryIcon from "@mui/icons-material/Category";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { PricingComponent } from "pages/plans/component/plan";
import { useEffect } from "react";
import { AiFillCreditCard, AiOutlineKey } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { classNames } from "utils";
import BankDetails from "./tabs/bankDetails";
import { ChangePassword } from "./tabs/changePassword";
import General from "./tabs/general";

function Settings() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const _tab = searchParams.get("tab");
  const { user } = useAuthContext();

  useEffect(() => {
    if (!_tab) {
      navigate("/settings?tab=account");
    }
  }, []);

  return (
    <RouteGuard>
      <Layout title="Settings">
        <div className="mx-auto max-w-7xl pb-24 lg:flex lg:gap-x-16 lg:px-8">
          <aside className="flex overflow-x-auto border-b border-gray-900/5 lg:block lg:w-64 lg:flex-none lg:border-0 py-8">
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                <li>
                  <Link
                    to="/settings?tab=account"
                    className={classNames(
                      _tab == "account"
                        ? "bg-blue-100 text-grottoBlue"
                        : "text-gray-700 hover:text-grottoBlue hover:bg-grottoBlue/10",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}>
                    <BiUserCircle
                      className={classNames(
                        _tab == "account"
                          ? "text-grottoBlue"
                          : "text-gray-400 group-hover:text-grottoBlue",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    Account
                  </Link>
                </li>

                {user?.type == "email" && (
                  <li>
                    <Link
                      to="/settings?tab=change-password"
                      className={classNames(
                        _tab == "change-password"
                          ? "bg-blue-100 text-grottoBlue"
                          : "text-gray-700 hover:text-grottoBlue hover:bg-grottoBlue/10",
                        "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                      )}>
                      <AiOutlineKey
                        className={classNames(
                          _tab == "change-password"
                            ? "text-grottoBlue"
                            : "text-gray-400 group-hover:text-grottoBlue",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      Change Password
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    to="/settings?tab=subscription"
                    className={classNames(
                      _tab == "subscription"
                        ? "bg-blue-100 text-grottoBlue"
                        : "text-gray-700 hover:text-grottoBlue hover:bg-grottoBlue/10",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}>
                    <AiFillCreditCard
                      className={classNames(
                        _tab == "subscription"
                          ? "text-grottoBlue"
                          : "text-gray-400 group-hover:text-grottoBlue",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    Billing
                  </Link>
                </li>

                <li>
                  <Link
                    to="/settings?tab=plans"
                    className={classNames(
                      _tab == "plans"
                        ? "bg-blue-100 text-grottoBlue"
                        : "text-gray-700 hover:text-grottoBlue hover:bg-grottoBlue/10",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}>
                    <CategoryIcon
                      className={classNames(
                        _tab == "plans"
                          ? "text-grottoBlue"
                          : "text-gray-400 group-hover:text-grottoBlue",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    Plans
                  </Link>
                </li>
              </ul>
            </nav>
          </aside>

          <main className="px-4 py-8 sm:px-6 lg:flex-auto lg:px-0">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              {_tab == "account" && <General />}

              {_tab == "subscription" && <BankDetails />}

              {_tab == "change-password" && <ChangePassword />}
              
              {_tab == "plans" && <PricingComponent heading="false" />}
            </div>
          </main>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Settings;
