import emailjs from "@emailjs/browser";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog } from "@mui/material";
import { cancelSubscription } from "apis";
import { useAuthContext } from "contexts/authContext";
import { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { toast } from "react-toastify";


export default function CancelSubscriptionModal({
  open,
  handleClose,
  payload,
}) {
  const serviceKey = process.env.REACT_APP_EMAILJS_CANCEL_SUB_SERVICE_CODE;
  const cancelSubTemplateKey =
    process.env.REACT_APP_EMAILJS_CANCEL_SUB_TEMPLATE_KEY;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const { setUserTier, userTier, user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const handleCancelSubscription = async () => {
    setLoading(true);
    await cancelSubscription()
      .then((res) => {
        // console.log(res);
        setUserTier({ tier: "free", plan: null });
        // sendMail();
        toast?.success("Successfully cancelled the subscription.");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error canceling the subscription.");
      });
  };

  // console.log(user)

  const sendMail = async () => {
    await emailjs
      .send(
        "",
        "",
        {
          from_name: "noteszen",
          to_name: user?.name,
          reply_to: user?.email,
          message: `You have cancelled your noteszen ${userTier?.tier} plan.`,
        },
        publicKey
      )
      .then((res) => {
        // console.log(res);
        toast?.success("Successfully cancelled the subscription.");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Cancelling plan.");
      });
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <div className="text-center flex flex-col items-center w-full my-2 opacity-80">
              <BiError
                style={{ fontSize: "3rem", opacity: "1", color: "red" }}
              />
              <h3 className="text-2xl font-semibold text-center">
                Are You Sure!
              </h3>
              <p>You want to cancel your current subscription.</p>
            </div>
            <div className="flex w-full gap-2 pt-4 justify-center">
              <Button
                variant="outlined"
                onClick={() => handleClose()}
                className="bg-white text-red">
                No
              </Button>
              <LoadingButton
                onClick={() => handleCancelSubscription()}
                variant="contained"
                loading={loading}>
                Agree
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
