import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { verifyOtpAPI } from "apis";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLocalData } from "utils/storage";

export function OTP() {
  const navigate = useNavigate();
  const { token, user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const _email = searchParams.get("email");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();

  const handleVerifyOTP = async (e) => {
    setLoading(true);
    e?.preventDefault();
    if (email && otp?.length == 6) {
      await verifyOtpAPI({ email, otp })
        .then((res) => {
          // console.log(res);
          if (res?.data?.status == "fail") {
            toast.error(res?.data?.message || "Error Verify OTP.");
          } else {
            toast.success("Successfully verified OTP.");
            setLocalData("@nz__l", res?.data?.result?.token);
            setLocalData(
              "@nz__user",
              JSON.stringify({
                ...res?.data?.result?.user,
                type: "email",
                photoURL: res?.data?.result?.user?.photoURL,
              })
            );

            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          toast?.error(err?.response?.data?.message || "Error verify otp.");
        });
    } else {
      toast?.error("Kindly fill the form");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    } else {
      setEmail(_email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, _email]);

  return (
    <Layout>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
          <h2 className="my-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Verify OTP
          </h2>
        </div>
        <div className="mt-10 mb-[3rem] sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white p-6 shadow-lg sm:rounded-lg sm:px-8">
            <form className="space-y-6" onSubmit={handleVerifyOTP}>
              <div className="flex flex-col justify-center gap-2 my-6">
                <TextField
                  fullWidth
                  required
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                />
                <div className="my-2">
                  <p className="text-sm text-gray-500">Enter OTP</p>
                  <MuiOtpInput value={otp} onChange={setOtp} length={6} />
                </div>
              </div>
              <div>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={Boolean(loading)}
                  type="submit">
                  Verify
                </LoadingButton>
              </div>
            </form>

            <div>
              <p className="text-sm text-gray-400 mt-6">
                we jusy sent you an OTP code in your email that you have
                entered. Kindly copy that code and paste it above to verify your
                email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
