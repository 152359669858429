export const contactEmail = "contact@NotesZen.com";

export const stripeLinks = {
  basicMonthly:
    "https://buy.stripe.com/4gw03E9indx3cY8cMM?prefilled_email=",
  basicYearly:
    "https://buy.stripe.com/aEU3fQbqv64BbU4002?prefilled_email=",
  premiumMonthly:
    "https://buy.stripe.com/7sI8Aaamr50x4rC145?prefilled_email=",
  premiumYearly:
    "https://buy.stripe.com/9AQg2C0LRgJf1fqdQT?prefilled_email=",
};
