import { Button } from "@mui/material";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import { useEffect } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

export function SessionExpired() {
  const navigate = useNavigate();
  const { token } = useAuthContext();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <Layout title="Session Expired">
      <div className="h-[70vh] flex items-center justify-center">
        <div className="">
          <div className="p-6 md:mx-auto text-center flex flex-col items-center">
            <BiErrorCircle className="text-6xl text-red-600" />
            <div className="text-center">
              <h3 className="md:text-3xl text-base text-gray-900 font-semibold text-center">
                Session Expired!
              </h3>
              <p className="text-gray-600 my-2">
                Login again to access your account.
              </p>
              {/* <p> Have a great day! </p> */}
              <div className="py-10 flex gap-4 w-full justify-center text-center">
                <a href="/">
                  <Button variant="contained" >Home</Button>
                </a>
                <Link to="/login">
                  <Button variant="contained">
                    Sign In
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
