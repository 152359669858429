import moment from "moment";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function capitalizeFirstLetter(inputString) {
  if (!inputString) {
    return inputString;
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function formateDate(date) {
  return moment(date).format("MMM D, YYYY");
}
