import {
  addEncounterAPI,
  generateEmrAPI,
  getSingleEncounterAPI,
  getSinglePatientAPI,
  getTemplatesApi,
  updateSingleEncounterAPI,
} from "apis";
import { useAuthContext } from "contexts/authContext";
import useNotification from "hooks/useNotifications";
import debounce from "lodash.debounce";
import {
  AudioConfig,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
} from "microsoft-cognitiveservices-speech-sdk";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchEncounters } from "store/encounterSlice";

export function useVisit() {
  const params = useParams();
  const { visitId } = params;
  const [searchParams] = useSearchParams();
  const _patientId = searchParams.get("patientId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    token,
    handleSessionExpired,
    checkTier,
    user,
    handleUpdateIntroStatus,
  } = useAuthContext();
  const { encounters } = useSelector((state) => state.encounters);
  const [range, setRange] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [sickVisitTemplates, setSickVisitTemplates] = useState();
  const [visit, setVisit] = useState();
  const [generatingEMR, setGeneratingEMR] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recognition, setRecognition] = useState(false);
  const [transcription, setTranscription] = useState(" ");
  const [trans, setTrans] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification(
    "/notification.mp3",
    "/favicon.png"
  );
  const [allowAutoFetch, setAllowAutoFetch] = useState(true);
  const [allowAutoSave, setAllowAutoSave] = useState(true);
  const [status, setStatus] = useState();
  const [error, setError] = useState(false);

  const azureSpeechKey = process.env.REACT_APP_AZURE_SPEECH_KEY;
  const azureSpeechLang = process.env.REACT_APP_AZURE_SPEECH_LANG;

  const steps = [
    {
      target: "#firstName",
      content: "Enter Patient's First Name.",
      placement: "bottom",
    },
    {
      target: "#lastName",
      content: "Enter Patient's Last Name.",
      placement: "bottom",
    },
    {
      target: "#template",
      content: "Select the Template",
      placement: "top",
    },
    {
      target: "#recorder",
      content: "Click to start/stop recording.",
      placement: "top",
    },
    {
      target: "#generateEMR",
      content: "Click to generate EMR.",
      placement: "top",
    },
  ];

  const handleJoyrideCallback = async (data) => {
    console.log(data.action);
    if (data.action === "reset") {
      handleUpdateIntroStatus();
    }
  };

  const startRecording = async () => {
    setLoading("startingRecording");
    setAllowAutoFetch(false);
    try {
      const res = await checkTier();
      console.log(res);
      setLoading(false);
      if (res?.tier != "free") {
        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const speechConfig = SpeechConfig.fromSubscription(
          azureSpeechKey,
          azureSpeechLang
        );
        const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
        recognizer.recognizing = (s, e) => {
          if (e.result.reason === ResultReason.RecognizingSpeech) {
            handleSetTrans(e.result.privOffset, e.result.text);
          }
        };

        recognizer.startContinuousRecognitionAsync();

        setIsRecording(true);
        setRecognition(recognizer);
        // console.log(azureSpeechKey);
        // console.log(azureSpeechLang);
      } else {
        toast?.error("You are on Free plan. Kindly upgrade your plan");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(
        err?.response?.message ||
          "You are on Free plan. Kindly upgrade your plan"
      );
    }
  };

  const getPatientDetails = async () => {
    await getSinglePatientAPI({ id: _patientId })
      .then((res) => {
        console.log(res?.data?.result);
        setVisit({
          patient: {
            ...res.data.result,
            entry_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const handleNewVisit = () => {
    setVisit({
      entry_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      patient: {
        dob: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
    });
    setTrans([]);
    setTranscription(" ");
    setAllowAutoFetch(false);
  };

  const handleSetTrans = (privOffset, value) => {
    const createdAt = Date.now();

    setTrans((prevState) => ({
      ...prevState,
      [privOffset]: { value, createdAt },
    }));
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stopContinuousRecognitionAsync();
      setIsRecording(false);
      setAllowAutoFetch(true);
    }
  };

  const clearTranscription = () => {
    if (recognition) {
      recognition.stopContinuousRecognitionAsync();
    }
    setRecognition(null);
    setTranscription("");
    setTrans([]);
    setAllowAutoFetch(false);
    setVisit({ ...visit, transcription: "" });
  };

  const handleGenerateEMR = async () => {
    if (
      visit?.patient?.first_name?.length < 1 ||
      visit?.patient?.last_name?.length < 1 ||
      visit?.patient?.dob?.length < 1 ||
      !visit?.template
    ) {
      toast?.error("Enter the Patient Details and Select Template.");
    } else {
      setGeneratingEMR(true);
      // setAllowAutoFetch(false);
      // await handleSubmitVisit({ _visit: visit });
      // Top Stop the reocrding
      if (recognition) {
        recognition.stopContinuousRecognitionAsync();
        setIsRecording(false);
      }
      try {
        const tierResponse = await checkTier();
        if (tierResponse?.tier !== "free") {
          await generateEmrAPI({
            templateId: visit?.template?.id,
            transcription: `${visit?.transcription} ${transcription || " "}`,
          })
            .then((res) => {
              // console.log(res);
              // alert(JSON.stringify(res?.data));
              if (res?.data?.status == "success") {
                setVisit({
                  ...visit,
                  // transcription: `${visit?.transcription} ${
                  //   transcription || " "
                  // }`,
                  // transcriptionNew: "",
                  emr: { note: res?.data?.result?.emr },
                });
                if (window?.innerWidth > 600) {
                  showNotification("Successfully generated EMR.");
                } else {
                  toast?.success("Successfully generated EMR.");
                }
                handleAutoSave({
                  ...visit,
                  transcription: `${visit?.transcription} ${
                    transcription || " "
                  }`,
                  transcriptionNew: "",
                  emr: { note: res?.data?.result?.emr },
                });
              } else {
                toast.error(res?.data?.message || "Error Generating EMR.");
              }
            })
            .catch((err) => {
              // alert(JSON.stringify(res?.data));
              console.log(err);
              toast?.error(
                err?.response?.data?.message || "Error Generating EMR."
              );
            });
        } else {
          toast?.error("Click on pricing to purchase a plan.");
        }
      } catch (tierError) {
        console.log(tierError);
        toast?.error(
          tierError?.response?.message ||
            "You are on Free plan. Kindly upgrade your plan"
        );
      }
      setGeneratingEMR(false);
      setAllowAutoFetch(true);
    }
  };

  const handleSubmitVisit = async ({ type, _visit }) => {
    setSubmiting(type);
    setStatus("saving...");
    setAllowAutoFetch(false);
    try {
      const res = await checkTier();
      if (res?.tier !== "free") {
        const __visit = {
          patient: {
            first_name: _visit?.patient?.first_name,
            last_name: _visit?.patient?.last_name,
            dob: moment(_visit?.patient?.dob).format("YYYY-MM-DDTHH:mm:ss"),
          },
          entry_date: moment(_visit?.entry_date).format("YYYY-MM-DDTHH:mm:ss"),
          note: _visit?.emr?.note,
          transcription: `${_visit?.transcription || " "}`,
          physician_notes: _visit?.template?.physician_note,
          template_id: _visit?.template?.id,
        };
        // to update visit
        console.log("__visit:", __visit);
        if (_visit?.id) {
          await updateSingleEncounterAPI({
            encounter: __visit,
            encounterId: _visit?.id,
          })
            .then((res) => {
              // console.log(res);
              if (res?.data?.status == "fail") {
                toast.error(res?.data?.message || "Error Submitting visit.");
                setSubmiting(false);
              } else {
                if (type) {
                  toast.success("Successfully Update visit.");
                }
                // navigate(`/visit/${res?.data?.result?.id}`);

                setAllowAutoFetch(true);
              }
            })
            .catch((err) => {
              console.log(err);
              toast.success(
                err?.response?.data?.message || "Error Updating Visit."
              );
            });
          setSubmiting(false);
        } else {
          // to create visit
          if (
            __visit?.patient?.first_name?.length < 1 ||
            __visit?.patient?.last_name?.length < 1 ||
            __visit?.patient?.dob?.length < 1
          ) {
            toast.error("Enter Patient Details.");
          } else {
            await addEncounterAPI({ encounter: __visit })
              .then((res) => {
                // console.log(res);
                if (res?.data?.status == "fail") {
                  toast.error(res?.data?.message || "Error Submitting visit.");
                  setSubmiting(false);
                } else {
                  // dispatch(
                  //   setEncounters({
                  //     encounters: [
                  //       ...encounters?.encounters,
                  //       { ...visit, id: res?.data?.result?.id },
                  //     ],
                  //   })
                  // );
                  toast.success("Successfully Add visit.");
                  navigate(`/visit/${res?.data?.result?.id}`);
                  setAllowAutoFetch(true);
                }
              })
              .catch((err) => {
                console.log(err);
                toast.success(
                  err?.response?.data?.message || "Error Adding Visit."
                );
              });
          }
        }
        if (type == "export") {
          copyText();
        }
      } else {
        toast?.error("Click on pricing to purchase a plan.");
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.message ||
          "You are on Free plan. Kindly upgrade your plan"
      );
    }
    setSubmiting(false);
    setStatus(null);
    setAllowAutoFetch(true);
  };

  const getTemplate = useCallback(async () => {
    await getTemplatesApi()
      .then((res) => {
        // const _templates = res.data.result?.forEach((obj) => {
        //   delete obj.group;
        //   delete obj.type;
        // });

        // console.log(_templates);
        setSickVisitTemplates(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  }, [token]);

  const getVisitDetails = useCallback(async () => {
    setStatus("fetching...");
    await getSingleEncounterAPI({ id: visitId })
      .then((res) => {
        // console.log(res);
        setVisit(res.data.result[0]);
        setTrans([]);
        setTranscription("");
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        } else {
          // toast?.error(err?.response?.data?.message || "Error Fetching Data.");
          setError("getVisitDetails");
          setAllowAutoFetch(false);
        }
      });
    setStatus(null);
  }, [visitId]);

  function markdownToPlainText(markdownText) {
    const replacements = [
      { pattern: /\*\*(.*?)\*\*/g, replacement: "$1" }, // Remove bold (**text**)
      { pattern: /\*\s\s+/g, replacement: "\n* " }, // Preserve bullet points
      { pattern: /(?:\*|\d+\.)\s+/g, replacement: "\n\n• " }, // Convert lists to bullet points
    ];

    let plainText = markdownText;

    replacements.forEach(({ pattern, replacement }) => {
      plainText = plainText.replace(pattern, replacement);
    });

    return plainText;
  }

  const copyText = () => {
    const textArea = document.createElement("textarea");
    textArea.value = markdownToPlainText(visit?.emr?.note);
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const handleChange = (value, field) => {
    if (["first_name", "last_name", "dob"].includes(field)) {
      setVisit({
        ...visit,
        patient: {
          ...visit?.patient,
          [field]: value,
        },
      });
      setAllowAutoFetch(false);
      handleAutoSave({
        ...visit,
        patient: {
          ...visit?.patient,
          [field]: value,
        },
      });
    } else if (field == "emr") {
      setVisit({
        ...visit,
        // transcription: `${visit?.transcription} ${transcription || " "}`,
        emr: {
          ...visit?.emr,
          note: value,
        },
      });
      // console.log(value != visit?.emr?.note);
      if (value != visit?.emr?.note) {
        setAllowAutoFetch(false);
        // This coused the issue of deleting last few words in transcripted text.
        handleAutoSave({
          ...visit,
          transcription: `${visit?.transcription} ${transcription || " "}`,
          emr: {
            ...visit?.emr,
            note: value,
          },
        });
      }
    } else {
      setVisit({
        ...visit,
        [field]: value,
      });
      handleAutoSave({
        ...visit,
        [field]: value,
      });
      setAllowAutoFetch(false);
    }
  };

  const handleAutoSave = useCallback(
    debounce((_visit) => {
      // if (allowAutoSave) {
      if (_visit) {
        // console.log("debounced fn should call");
        if (
          _visit?.patient?.first_name &&
          _visit?.patient?.last_name &&
          _visit?.patient?.dob &&
          _visit?.template
        ) {
          handleSubmitVisit({ _visit });
        }
      } else {
        // console.log("debounced fn shouldn't call");
      }
      // }
      // handleSubmitVisit();
    }, 2000),
    [transcription]
  );

  const handleFetchTodayEncounters = async () => {
    dispatch(
      fetchEncounters({
        startDate: range?.startDate,
        endDate: range?.endDate,
      })
    );
  };

  // console.log(visit);
  // console.log(sickVisitTemplates);
  // console.log(transcription);
  // console.log(recognition);
  // console.log(allowAutoFetch);

  useEffect(() => {
    if (token) {
      if (!sickVisitTemplates) {
        getTemplate();
      }
      if (visitId) {
        getVisitDetails();
      }
      if (!encounters) {
        handleFetchTodayEncounters();
      }
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (_patientId) {
        getPatientDetails();
      }
    }
  }, [_patientId]);

  useEffect(() => {
    if (visitId) {
      // const encounter = encounters.find((item) => item.id === visitId);
      // console.log('encounter');
      setVisit(null);
    } else {
      setVisit({
        ...visit,
        patient: {
          ...visit?.patient,
          dob: moment().format("YYYY-MM-DDTHH:mm:ss"),
        },
        entry_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
    }
    stopRecording();
  }, [visitId, encounters, _patientId]);

  useEffect(() => {
    const dataArray = Object.keys(trans).map((key) => trans[key]);
    dataArray.sort((a, b) => a.createdAt - b.createdAt);
    const concatenatedString = dataArray.map((item) => item.value).join(" ");

    setTranscription(concatenatedString);
    // setVisit({
    //   ...visit,
    //   transcription: `${visit?.transcription || ""} ${
    //     concatenatedString || ""
    //   }`,
    // });
  }, [trans]);

  // for Auto Save
  useEffect(() => {
    // for already existed visit
    if (visitId) {
      setVisit((prevVisit) => ({
        ...prevVisit,
        transcriptionNew: transcription || "",
        // transcription: `${visit?.transcription || ""} ${transcription || ""}`,
      }));
      handleAutoSave({
        ...visit,
        transcription: `${visit?.transcription || ""} ${transcription || ""}`,
      });
      // for new visit
    } else {
      setVisit((prevVisit) => ({
        ...prevVisit,
        transcription: `${transcription || ""}`,
      }));
      handleAutoSave({
        ...visit,
        transcription: `${transcription || ""}`,
      });
    }
  }, [transcription]);

  // for Auto Fetch
  useEffect(() => {
    if (allowAutoFetch && !isRecording && visitId && !generatingEMR) {
      var interval = setInterval(() => {
        getVisitDetails();
        // console.log("auto fetch called");
      }, 5000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [allowAutoFetch, isRecording, visitId, generatingEMR]);

  return {
    sickVisitTemplates,
    visitId,
    visit,
    setVisit,
    isRecording,
    stopRecording,
    startRecording,
    handleGenerateEMR,
    handleSubmitVisit,
    transcription,
    setTranscription,
    generatingEMR,
    clearTranscription,
    submiting,
    encounters: encounters?.encounters,
    handleNewVisit,
    loading,
    handleChange,
    handleAutoSave,
    status,
    error,
    steps,
    handleJoyrideCallback,
    user,
    handleFetchTodayEncounters,
  };
}
