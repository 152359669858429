import React from "react";
import { BiError } from "react-icons/bi";

function ErrorMsg({ text }) {
  return (
    <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
      <BiError style={{ fontSize: "4rem", opacity: "0.4" }} />
      {text || "Something went wrong"}
    </div>
  );
}

export default ErrorMsg;
