import { configureStore } from "@reduxjs/toolkit";
import encounterSlice from "./encounterSlice";
import userDetailSlice from "./userDetailSlice";

const store = configureStore({
  reducer: {
    encounters: encounterSlice,
    userDetails: userDetailSlice
  },
});

export default store;
