import { createTheme } from "@mui/material/styles";
import { COLORS } from "constants/colors";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.grottoBlue,
    },
    secondary: {
      main: COLORS.turqoise,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        // size: "large",
        variant: "contained",
        // fullWidth: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "9999px",
          // fontWeight: 'bold'
        },
      },
    },
    MuiPaper: {
      defaultProps: {},
      styleOverrides: {
        root: {},
        rounded: {
          borderRadius: `10px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {},
        title: {},
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "&:hover": {},
            "& .MuiListItemIcon-root": {},
          },
          "&:hover": {
            "& .MuiListItemIcon-root": {},
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        defaultProps: {
          fullWidth: true
        },
        input: {
          "&::placeholder": {},
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        // size: 'small'
        fullWidth: true
      },
      styleOverrides: {
        root: {
          borderRadius: `10px`,
          "& .MuiOutlinedInput-notchedOutline": {},
          "&:hover $notchedOutline": {},
          "&.MuiInputBase-multiline": {},
        },
        input: {
          borderRadius: "10px",
          "&.MuiInputBase-inputSizeSmall": {
            "&.MuiInputBase-inputAdornedStart": {},
          },
        },
        inputAdornedStart: {},
        notchedOutline: {
          borderRadius: "10px",
        },
      },
    },
    MuiTextField:{
      defaultProps: {
        fullWidth: true
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {},
        },
        mark: {},
        valueLabel: {},
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {},
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // size: 'small'
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: "top",
      },
    },
    MuiCircularProgress:{
      styleOverrides:{
        root:{
          color: COLORS.sienna
        }
      }
    }
  },
});
