import { LoadingButton } from "@mui/lab";
import { Button, Dialog } from "@mui/material";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { deleteCardDetailsAPI, updateCardDetailsAPI } from "apis";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchCardDetails } from "store/userDetailSlice";

export default function UpdateCardModal({ open, handleClose }) {
  const stripePublicKey = process.env.REACT_APP_STRIP_PUBLISHABLE_KEY;
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const { card } = useSelector((state) => state.userDetails);
  const stripePromise = loadStripe(stripePublicKey);
  const handleSubmit = async (elements, stripe) => {
    setUpdating(true);
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    try {
      const result = await stripe.createToken(card);
      if (result.error) {
        toast?.error(result.error.message || "Error updating card details");
      } else {
        await updateCardDetailsAPI({
          cardToken: result?.token?.id,
        })
          .then((res) => {
            // console.log(res);
            handleDeletePreviousCard();
          })
          .catch((err) => {
            // console.log(err);
            toast?.error(
              err.response?.data?.message || "Error Updating Card Details."
            );
          });
      }
    } catch (error) {
      toast?.error(error.message || "Error updating card details");
    }
  };

  const handleDeletePreviousCard = async () => {
    await deleteCardDetailsAPI({ cardToken: card?.id })
      .then((res) => {
        // console.log(res);
        dispatch(fetchCardDetails());
        handleClose();
        toast?.success("Successfully Updated Card Details.");
      })
      .catch((err) => {
        // console.log(err);
        toast?.error(
          err.response?.data?.message || "Error Deleting Previous Card Details."
        );
      });
    setUpdating(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Dialog open={open} className="relative z-10" onClose={() => {}}>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <h3 className="text-2xl font-semibold text-center">
                    Update Card Details
                  </h3>
                  <div>
                    <form className="mt-6">
                      <div className="my-8">
                        <div className="col-span-full">
                          <label
                            htmlFor="card-number"
                            className="mb-1 block text-sm font-medium text-blue">
                            Card Details
                          </label>
                          <div className="mt-1 border border-lightgray p-3 rounded-md">
                            <CardElement />
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full gap-2 justify-end">
                        <Button
                          onClick={() => handleClose()}
                          className="bg-white text-red">
                          Cancel
                        </Button>
                        <LoadingButton
                          onClick={() => handleSubmit(elements, stripe)}
                          loading={updating}>
                          Update Card
                        </LoadingButton>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </ElementsConsumer>
    </Elements>
  );
}
