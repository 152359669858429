
// SessionStorage
export const setSessionData = (key, value) => {
  sessionStorage.setItem(key, value);
  return;
};

export function getSessionData(key) {
  const data = sessionStorage.getItem(key);
  return data;
}

export function removeSessionData(key) {
  sessionStorage.removeItem(key);
}

// localStorage
export const setLocalData = (key, value) => {
  localStorage.setItem(key, value);
  return;
};

export function getLocalData(key) {
  const data = localStorage.getItem(key);
  return data;
}

export function removeLocalData(key) {
  localStorage.removeItem(key);
}

// Cookies
export const setCookie = (key, value) => {
  document.cookie = `${key} = ${value}; expires=0`;
};

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function removeCookie(name) {
  // console.log("removed");
  document.cookie = name + "=0; expires=Thu, 01 Jan 1800 00:00:00 UTC;";
}

export function removeAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}