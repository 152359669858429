import { DeleteOutline, EditSharp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { deleteTemplateAPI, getAdminTemplatesAPI } from "apis";
import Loader from "components/loader";
import { COLORS } from "constants/colors";
import { useAuthContext } from "contexts/authContext";
import AdminLayout from "fragments/admin/layout";
import RouteGuardAdmin from "hoc/routeGuardAdmin";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Templates() {
  const [templates, setTemplates] = useState();
  const { admin, adminToken, handleSessionExpired } = useAuthContext();
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const columnsForTemplates = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "title",
      label: "Template Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <span>{value?.title}</span>;
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (templateId) => {
          return (
            <div className="flex">
              <Tooltip title="Update Template">
                <Link to={`/admin/templates/${templateId}`}>
                  <IconButton sx={{ color: COLORS.grottoBlue }}>
                    <EditSharp />
                  </IconButton>
                  {/* <Button size="small" variant="contained">
                    View
                  </Button> */}
                </Link>
              </Tooltip>
              <Tooltip title="Delete Template">
                <IconButton
                  onClick={() =>
                    setTemplateToDelete(
                      templates.find((obj) => obj.id === templateId)
                    )
                  }
                  sx={{ color: COLORS.red }}>
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const optionsForTeamplates = {
    onRowClick: (user) => {
      // console.log(user);
      // const id = user[1];
      // navigate(`/users/${id}`);
    },
    responsive: "scroll",
    selectableRowsHideCheckboxes: true,
    // selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    searchOpen: true,
    rowsPerPageOptions: [50],
    elevation: 2,
    // filter: false,
    rowsPerPage: 50,
  };

  const getTemplates = async () => {
    await getAdminTemplatesAPI()
      .then((res) => {
        // console.log(res);
        setTemplates(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const handleDeleteType = async () => {
    setLoading(true);
    await deleteTemplateAPI(templateToDelete?.id)
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Deleting Template Type.");
        } else {
          handleDeleteTemplateInState();
          toast?.success("Successfully Deleted Template Type.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleDeleteTemplateInState = () => {
    setTemplates((prevArray) =>
      prevArray.filter((obj) => obj.id !== templateToDelete?.id)
    );
    setTemplateToDelete(null);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  // console.log(templates);

  return (
    <RouteGuardAdmin>
      <AdminLayout>
        <div className="m-5">
          <div className="flex justify-between">
            <h1 className="text-2xl">Templates</h1>
            <Link to="/admin/templates/add">
              <Button>Add</Button>
            </Link>
          </div>

          <div className="h-400 mt-5">
            {templates ? (
              <MUIDataTable
                title={"Templates"}
                data={templates}
                columns={columnsForTemplates}
                options={optionsForTeamplates}
              />
            ) : (
              <div className="h-[50vh]">
                <Loader />
              </div>
            )}
          </div>
        </div>

        {/* To Delete Type */}
        <Dialog
          open={Boolean(templateToDelete)}
          onClose={() => setTemplateToDelete(null)}>
          <DialogTitle>Delete Template</DialogTitle>
          <DialogContent>
            <div className="flex gap-2 items-center">
              <BiError className="text-red-600 text-3xl" />
              Confirm to Delete Template{" "}
              <span className="text-grottoBlue font-semibold">
                "{templateToDelete?.title}"
              </span>{" "}
              ?
            </div>
          </DialogContent>
          <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
            <Button
              variant="outlined"
              onClick={() => setTemplateToDelete(null)}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={() => handleDeleteType()}>
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </AdminLayout>
    </RouteGuardAdmin>
  );
}

export default Templates;
