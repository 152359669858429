import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSessionData, removeSessionData } from "utils/storage";

function RouteGuardAdmin({ children }) {
  const navigate = useNavigate();
  const _token = getSessionData("@nz__l");

  useEffect(() => {
    if (!_token) {
      removeSessionData("@nz__l");
      removeSessionData("@nz__user");
      window.location.replace("/login");
    } else {
      // navigate('/dashboard')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_token]);

  return <>{children}</>;
}

export default RouteGuardAdmin;
