import { ThemeProvider } from "@mui/material";
import { AuthState } from "contexts/authContext";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { muiTheme } from "utils/muiTheme";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "store/store";
import { Modals } from "contexts/modalContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <AuthState>
            <Modals>
                <App />
            </Modals>
          </AuthState>
        </Provider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
