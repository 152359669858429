import { BiBoltCircle } from "react-icons/bi";
import { SlSpeech } from "react-icons/sl";
import { BsFileEarmarkMedicalFill } from "react-icons/bs";
import { FaClinicMedical } from "react-icons/fa";
import { MdRateReview } from "react-icons/md";

export const primaryFeatures = [
  {
    name: "Real-Time Transcript",
    description:
      "Automatic generation of a transcript while you are having a conversation with a patient.",
    href: "#",
    icon: BiBoltCircle,
  },
  {
    name: "EMR Integration",
    description:
      "Your medical note is imported into the correct sections of your EMR.",
    href: "#",
    icon: BsFileEarmarkMedicalFill,
  },
  {
    name: "Platform Compatibility",
    description: "Use NotesZen with ease on your Mac or PC.",
    href: "#",
    icon: BiBoltCircle,
  },
  {
    name: "Customized Prompts",
    description:
      "A library of tailored prompts is included so that you can generate any note type.",
    href: "#",
    icon: SlSpeech,
  },
  {
    name: "Pediatric Specific Well Visits",
    description:
      "All well visit notes follow American Academy of Pediatrics guidelines and are customized with age specific guidelines, risk assessments, development milestones and more.",
    href: "#",
    icon: FaClinicMedical,
  },
  {
    name: "Instant Notes Generation",
    description: "Your note is ready for review in under one minute.",
    href: "#",
    icon: MdRateReview,
  },
];

export const secondaryFeatures = [
  {
    name: "Push to deploy.",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.",
    icon: BiBoltCircle,
  },
  {
    name: "SSL certificates.",
    description:
      "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.",
    icon: BiBoltCircle,
  },
  {
    name: "Simple queues.",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.",
    icon: BiBoltCircle,
  },
  {
    name: "Advanced security.",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.",
    icon: BiBoltCircle,
  },
  {
    name: "Powerful API.",
    description:
      "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.",
    icon: BiBoltCircle,
  },
  {
    name: "Database backups.",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ",
    icon: BiBoltCircle,
  },
];

export const benefits = [
  {
    name: "Saves You Time:",
    description: "NotesZen reduces documentation time by up to 2 hours",
    icon: BiBoltCircle,
  },
  {
    name: "Return on Investment:",
    description:
      "NotesZen allows you to comfortably see more patients per day.",
    icon: BiBoltCircle,
  },
  {
    name: "Enhanced Documentation:",
    description:
      "NotesZen accurately records anything discussed during the visit including all patient information, physician assessment and plan, and ICD10 codes.",
    icon: BiBoltCircle,
  },
  {
    name: "Improved Patient Outcomes:",
    description:
      "NotesZen increases the reliability and accuracy of documentation allowing you to provide better care.",
    icon: BiBoltCircle,
  },
];

export const templates = [
  {
    name: "17 year old well visit (Female)",
  },
  {
    name: "Sick Visits",
  },
  {
    name: "Well Visits(Female)",
  },
  {
    name: "Well Visits(Male)",
  },
];

export const wellVisits = [
  "Newborn Well Visit",
  "1 Month Well Visit",
  "2 Month Well Visit",
  "4 Month Well Visit",
  "6 Month Well Visit",
  "9 Month Well Visit",
  "12 Month Well Visit",
  "15 Month Well Visit",
  "18 Month Well Visit",
  "24 Month Well Visit",
  "30 Month Well Visit",
  "3 Year Well Visit",
  "4 Year Well Visit",
  "5-6 Year Well Visit",
  "7-8 Year Well Visit",
  "9-10 Year Well Visit",
  "11-14 Year Well Visit",
  "15-17 Year Well Visit",
  "18-21 Year Well Visit",
];

export const sickVisits = [
  "Acute Illness Sick Visit",
  "Chronic Illness Visit",
  "Medication Check",
  "Weight Check",
];
