import { benefit, benefits } from "data/home";
import React from "react";

export function Benefits() {
  return (
    <div className="bg-grottoBlue">
      <div className="mx-auto mt-32 max-w-7xl px-6 py-24 lg:px-8 my-10">
        <p className="mt-2 text-3xl font-bold text-white text-center w-full sm:text-4xl">
          Benefits
        </p>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {benefits.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-lg font-semibold leading-7 text-white">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-babyBlue">
                    <feature.icon
                      className="h-6 w-6 text-grottoBlue"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-white">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
