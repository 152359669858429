import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { getSubscription, updateUserInfoAPI } from "apis";
import GeneralDialogue from "components/modals/generalDialogue";
import { stripeLinks } from "constants";
import { useAuthContext } from "contexts/authContext";
import { useModalsContext } from "contexts/modalContext";
import { BiCheck } from "react-icons/bi";
import { toast } from "react-toastify";
import { classNames } from "utils";
import { setLocalData } from "utils/storage";

const frequencies = [
  {
    value: "monthly",
    label: "Monthly",
    priceSuffix: "/month",
    planDuration: "monthly",
  },
  {
    value: "annually",
    label: "Annually",
    priceSuffix: "/month",
    planDuration: "yearly",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "basic",
    href: "#",
    price: { monthly: "$300", annually: "$270" },
    features: [
      "Real-time transcription",
      "Physician Built templates",
      "Instant note generation",
      "100 notes per month",
    ],
    mostPopular: false,
  },
  {
    name: "Premium",
    id: "premium",
    href: "#",
    price: { monthly: "$600", annually: "$540" },
    features: [
      "Real-time transcription",
      "Physician Built templates",
      "Instant note generation",
      "Unlimited notes",
      "Free integration to EMR (with yearly commitment)",
    ],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "enterprise",
    href: "#",
    price: {
      monthly: "Contact us for a quote",
      annually: "Contact us for a quote",
    },
    features: [
      "Real-time transcription",
      "Individually Customized templates",
      "Instant note generation",
      "Unlimited notes",
      "Free integration to EMR (with yearly commitment)",
      "Unlimited Support",
    ],
    mostPopular: false,
  },
];

export function PricingComponent({ heading }) {
  const [frequency, setFrequency] = useState(frequencies[1]);
  const { userTier, token, user, handleSessionExpired } = useAuthContext();
  const { toggleCancelSubscriptionModal } = useModalsContext();
  const [open, setOpen] = useState(false);
  const [infoDialogue, setInfoDialogue] = useState(false);
  const [loading, setLoading] = useState();
  const [whatToBuy, setWhatToBuy] = useState();

  const handleBuyPlan = async (whatToBuyParam) => {
    const _whatToBuy = whatToBuy || whatToBuyParam;
    setLoading(whatToBuy?.planId);

    await getSubscription()
      .then((res) => {
        const tierInfo = res?.data?.result;

        if (tierInfo?.plan) {
          // toast.info("Current Plan");
          setOpen(true);
        } else {
          // no plan bought yet
          if (_whatToBuy?.planDuration == "monthly") {
            if (_whatToBuy?.planId == "basic") {
              window.location.assign(
                `${stripeLinks?.basicMonthly}${user?.email}`
              );
            } else if (_whatToBuy?.planId == "premium") {
              window.location.assign(
                `${stripeLinks?.premiumMonthly}${user?.email}`
              );
            }
          } else if (_whatToBuy?.planDuration == "yearly") {
            if (_whatToBuy?.planId == "basic") {
              window.location.assign(
                `${stripeLinks?.basicYearly}${user?.email}`
              );
            } else if (_whatToBuy?.planId == "premium") {
              window.location.assign(
                `${stripeLinks?.premiumYearly}${user?.email}`
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);

        if (err?.response?.status == 401) {
          handleSessionExpired();
        } else {
          toast.error(
            err?.response?.data?.message || "Error Checking for payment."
          );
        }
      });
    setLoading(false);
  };

  const [userData, setUserData] = useState();
  const [submiting, setSubmiting] = useState(false);

  const handleSubmit = async () => {
    setSubmiting(true);
    await updateUserInfoAPI({
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      phone_number: userData?.phone_number,
      organization_name: userData?.organization_name,
      physicians_count: userData?.physicians_count,
      organization_address: userData?.organization_address,
      electronic_medical_record: userData?.electronic_medical_record,
      how_did_you_learn: userData?.how_did_you_learn,
      special_request: userData?.special_request,
      practice_name: userData?.practice_name,
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Something went wrong.");
        } else {
          handleBuyPlan();
          setLocalData(
            "@nz__user",
            JSON.stringify({
              ...user,
              ...userData,
              type: "email",
              photoURL: user?.photoURL,
            })
          );
          setInfoDialogue(false);
          toast?.success(res?.data?.message || "Successfully updated.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status == 401) {
          handleSessionExpired();
        } else {
          toast.error(err?.response?.message || "Something went wrong.");
        }
      });
    setSubmiting(false);
  };

  const handleClickBuyPlanButton = ({ planId, planDuration }) => {
    setWhatToBuy({ planId, planDuration });
    if (
      user?.phone_number &&
      user?.organization_name &&
      user?.physicians_count &&
      user?.organization_address &&
      user?.electronic_medical_record &&
      user?.how_did_you_learn &&
      user?.special_request &&
      user?.practice_name
    ) {
      handleBuyPlan({ planId, planDuration });
      // console.log("All okay");
    } else {
      setInfoDialogue(true);
      // console.log("All not okay");
      // handleSubmit({ planId, planDuration });
    }
  };

  useEffect(() => {
    setUserData(user);
  }, [user]);

  return (
    <div className="py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-grottoBlue">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Choose the right plan for you
          </p>
        </div>
        {/* <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Choose an affordable plan that's packed with the best features for
            engaging your audience, creating customer loyalty, and driving
            sales.
          </p> */}
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-grottoBlue text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }>
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-grottoBlue"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8 xl:p-10"
              )}>
              {userTier?.tier == tier.id &&
                userTier?.plan == (frequency?.planDuration || "yealy") && (
                  <p className="float-right text-white bg-grottoBlue text-sm px-2 rounded-full">
                    Current plan
                  </p>
                )}

              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? "text-grottoBlue" : "text-gray-900",
                    "text-lg font-semibold leading-8"
                  )}>
                  {tier.name}
                </h3>
                {/* {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                      Most popular
                    </p>
                  ) : null} */}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              {tier?.id == "enterprise" ? (
                <div className="my-8">
                  <span className="text-xl py-4 font-semibold leading-6 text-gray-600">
                    {tier.price[frequency.value]}
                  </span>
                </div>
              ) : (
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}
                  </span>
                </p>
              )}
              <div className="mt-6">
                {userTier?.tier == tier.id &&
                userTier?.plan == frequency?.planDuration ? (
                  <Button
                    onClick={() => toggleCancelSubscriptionModal()}
                    variant="outlined"
                    color="error"
                    fullWidth>
                    Cancel Plan
                  </Button>
                ) : (
                  <>
                    {tier?.id != "enterprise" ? (
                      <LoadingButton
                        loading={Boolean(loading == tier?.id)}
                        onClick={() =>
                          handleClickBuyPlanButton({
                            planId: tier?.id,
                            planDuration: frequency?.planDuration,
                          })
                        }
                        fullWidth
                        variant={
                          tier?.id == "premium" ? "contained" : "outlined"
                        }>
                        Buy plan
                      </LoadingButton>
                    ) : (
                      <LoadingButton fullWidth variant="outlined">
                        Contact us
                      </LoadingButton>
                    )}
                  </>
                )}
              </div>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <BiCheck
                      className="h-6 w-5 flex-none text-grottoBlue"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <GeneralDialogue
        open={open}
        payload={{
          heading: "Are You Sure!",
          text: "To buy a new subscription, you must have to cancel your current subscription.",
        }}
        onAgree={() => {
          setOpen(false);
          toggleCancelSubscriptionModal();
        }}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
      />

      <Dialog
        open={Boolean(infoDialogue)}
        onClose={() => setInfoDialogue(false)}>
        <DialogTitle>
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Enter Your Details
          </h2>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col sm:w-[500px] gap-3 items-center my-4">
            <TextField
              label="First Name"
              value={userData?.first_name}
              sx={{ width: "fitContent" }}
              onChange={(e) =>
                setUserData({ ...userData, first_name: e?.target?.value })
              }
            />
            <TextField
              label="Last Name"
              value={userData?.last_name}
              sx={{ width: "fitContent" }}
              onChange={(e) =>
                setUserData({ ...userData, last_name: e?.target?.value })
              }
            />
            <TextField
              label="Phone Number"
              value={userData?.phone_number}
              onChange={(e) =>
                setUserData({ ...userData, phone_number: e?.target?.value })
              }
            />
            <TextField
              label="Organization Name"
              value={userData?.organization_name}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  organization_name: e?.target?.value,
                })
              }
            />
            <TextField
              type="number"
              label="Number of physicians in your organization"
              value={userData?.physicians_count}
              inputProps={{
                min: "1",
                step: "1",
              }}
              sx={{ width: "fitContent" }}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  physicians_count:
                    !isNaN(e.target.value) &&
                    parseInt(e.target.value) >= 1 &&
                    parseInt(e.target.value),
                })
              }
            />
            <TextField
              label="Address"
              value={userData?.organization_address}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  organization_address: e?.target?.value,
                })
              }
            />
            <TextField
              label="Electronic Medical Record"
              value={userData?.electronic_medical_record}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  electronic_medical_record: e?.target?.value,
                })
              }
            />
            <TextField
              label="How did you learn about NotesZen? (Optional) (free text)"
              value={userData?.how_did_you_learn}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  how_did_you_learn: e?.target?.value,
                })
              }
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
          <Button variant="outlined" onClick={() => setInfoDialogue(null)}>
            Cancel
          </Button>
          <LoadingButton
            loading={submiting}
            variant="contained"
            onClick={() => handleSubmit()}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
