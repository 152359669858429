import { EditSharp } from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { getPatientsVisitsAPI } from "apis";
import { Container } from "components/common/container";
import Loader from "components/loader";
import { COLORS } from "constants/colors";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formateDate } from "utils";

function PatientProfile() {
  const params = useParams();
  const { id } = params;
  const [visits, setVisits] = useState();
  const { user, token, handleSessionExpired } = useAuthContext();

  const columnsForTemplates = [
    {
      name: "",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "patient",
      label: "Patient's Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <span>
              {value?.first_name} {value?.last_name}
            </span>
          );
        },
      },
    },
    {
      name: "patient",
      label: "Patient's DOB",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <span>{formateDate(value?.dob)}</span>;
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (templateId) => {
          return (
            <div className="flex">
              <Tooltip title="Update Template">
                <Link to={`/visit/${templateId}`}>
                  <IconButton sx={{ color: COLORS.grottoBlue }}>
                    <EditSharp />
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const optionsForTeamplates = {
    onRowClick: (user) => {
      // console.log(user);
      // const id = user[1];
      // navigate(`/users/${id}`);
    },
    responsive: "scroll",
    selectableRowsHideCheckboxes: true,
    // selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    searchOpen: true,
    rowsPerPageOptions: [50],
    elevation: 2,
    // filter: false,
    rowsPerPage: 50,
  };

  const getVisits = async () => {
    setVisits(null);
    await getPatientsVisitsAPI({ id })
      .then((res) => {
        // console.log(res);
        setVisits(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  useEffect(() => {
    if (token && id) {
      getVisits();
    }
  }, [token, id]);

  // console.log(visits);
  // console.log(id);

  return (
    <RouteGuard>
      <Layout>
        <Container>
          <div>
            <div className="flex justify-between">
              <h1 className="text-2xl">Patient's Visits</h1>
            </div>
            {visits ? (
              <>
                <div className="px-4 py-2 bg-white rounded-lg shadow">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full sm:py-2">
                    <TextField
                      label="Patient's first name"
                      value={visits[0]?.patient?.first_name || ""}
                    />
                    <TextField
                      label="Patient's last name"
                      value={visits[0]?.patient?.last_name || ""}
                    />
                    <TextField
                      type="date"
                      label="Patient's DOB"
                      value={moment(visits[0]?.patient?.dob || "").format(
                        "YYYY-MM-DD"
                      )}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="h-400 mt-5">
                  <MUIDataTable
                    title={"All "}
                    data={visits}
                    columns={columnsForTemplates}
                    options={optionsForTeamplates}
                  />
                </div>
              </>
            ) : (
              <div className="h-[50vh]">
                <Loader />
              </div>
            )}
          </div>
        </Container>
      </Layout>
    </RouteGuard>
  );
}

export default PatientProfile;
