import { useState, useEffect } from "react";

const useNotification = (defaultSoundUrl = null, defaultIconUrl = null) => {
  const [permission, setPermission] = useState("default");

  useEffect(() => {
    // Check for browser notification support and permission status on mount
    if ("Notification" in window) {
      Notification.requestPermission().then((newPermission) => {
        setPermission(newPermission);
      });
    }
  }, []);

  const showNotification = (
    title,
    options = {},
    soundUrl = defaultSoundUrl,
    iconUrl = defaultIconUrl
  ) => {
    if (permission === "granted") {
      // If permission is granted, construct the notification options
      const notificationOptions = {
        ...options,
        icon: iconUrl,
      };

      // Display the notification
      const notification = new Notification(title, notificationOptions);

      // Play sound if a specific sound URL is provided or use default sound
      if (soundUrl) {
        const audio = new Audio(soundUrl);
        audio.play();
      }

      return notification;
    } else if (permission !== "denied") {
      // If permission is not denied, request permission again
      Notification.requestPermission().then((newPermission) => {
        setPermission(newPermission);
        if (newPermission === "granted") {
          // Construct the notification options
          const notificationOptions = {
            ...options,
            icon: iconUrl,
          };

          // Display the notification
          const notification = new Notification(title, notificationOptions);

          // Play sound if a specific sound URL is provided or use default sound
          if (soundUrl) {
            const audio = new Audio(soundUrl);
            audio.play();
          }

          return notification;
        }
      });
    }
  };

  return { showNotification };
};

export default useNotification;
