import { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quil.css";

class ReactQuillEditorOld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.text,
    };
  }

  handleChange = (value, delta, source, editor) => {
    this.setState({ text: editor.getContents() });
    if (this.props.onChange) {
      // this.props.onChange(editor.getContents());
      this.props.onChange(value);
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // // Handle Enter key press to insert a line break
      // const quill = this.reactQuillRef.getEditor();
      // const cursorPosition = quill.getSelection().index;
      // quill.insertText(cursorPosition, "\n", "user");
      // quill.setSelection(cursorPosition + 1, "user");
      // e.preventDefault();
    } else if (e.key === " ") {
      // Handle space key press to insert a regular space
      const quill = this.reactQuillRef.getEditor();
      const cursorPosition = quill.getSelection().index;
      quill.insertText(cursorPosition, " ");
      quill.setSelection(cursorPosition + 1);
      e.preventDefault();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text) {
      this.setState({ text: this.props.text });
    }
  }

  render() {
    return (
      <div>
        <ReactQuill
          theme="snow"
          ref={(el) => (this.reactQuillRef = el)}
          value={this.state.text}
          onChange={(value, delta, source, editor) => this.handleChange(value, delta, source, editor)}
          onKeyDown={this.handleKeyDown}
          className={`h-[${this?.props?.height || "300px"}]`}
          // style={{height: '50vh'}}
          // modules={{
          //   toolbar: [
          //     [{ header: "1" }, { header: "2" }, { font: [] }],
          //     ["bold", "italic", "underline", "strike", "blockquote"],
          //     [{ list: "ordered" }, { list: "bullet" }],
          //     // ["link", "image", "video"],
          //     ["clean"],
          //   ],
          // }}
        />
      </div>
    );
  }
}

export default ReactQuillEditorOld;
