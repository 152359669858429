import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";

export default function Viewer(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the container when props.value changes
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [props.value]);

  return (
    <div
      ref={containerRef}
      className="p-2 "
      style={{ overflowY: "scroll", height: "400px", wordWrap: "break-word" }}>
      {props?.value?.length > 0 ? (
        <ReactMarkdown className="text-[14px] break-words w-[100%]">
          {props.value}
        </ReactMarkdown>
      ) : (
        <ReactMarkdown> </ReactMarkdown>
      )}
    </div>
  );
}
