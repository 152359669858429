import Layout from "fragments/layout/layout";
import { CallToAction } from "./components/cta";
import { Features } from "./components/features";
import Hero from "./components/hero";
import { Benefits } from "./components/stats";

export default function Home() {
  return (
    <Layout>
      <Hero />
      {/* <LogoCloud /> */}
      <Features />
      {/* <SecondaryFeatures /> */}
      <Benefits />
      <CallToAction />

      {/* <PrimaryFeatures /> */}
    </Layout>
  );
}
