import emailjs from "@emailjs/browser";
import { LoadingButton } from "@mui/lab";
import { Container } from "components/common/container";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import { useState } from "react";
import { toast } from "react-toastify";


export default function Support() {
  const serviceKey = process.env.REACT_APP_EMAILJS_SERVICE_CODE_FOR_SUPPORT;
  const templateKey = process.env.REACT_APP_EMAILJS_TEMPLATE_KEY_FOR_SUPPORT;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY_FOR_SUPPORT;
  const { user } = useAuthContext();
  const [data, setData] = useState();
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (data?.title?.length < 3 || data?.description?.length < 3) {
      toast?.error("Kindly fill the form.");
      return;
    } else {
      setSending(true);
      await emailjs
        .send(
          serviceKey,
          templateKey,
          {
            from_name: `${user?.firstName} ${user.lastName}`,
            from_email: data?.email,
            message: data?.description,
          },
          publicKey
        )
        .then((res) => {
          toast.success("Successfully Sent. We will contact you soon.");
          setData({
            title: "",
            description: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.success("Error Submiting.");
        });
      setSending(false);
    }
  };

  return (
    <Layout title="Support">
      <Container>
        <div className="w-full flex justify-center">
          <form
            onSubmit={handleSubmit}
            className="relative w-full max-w-[800px] my-24">
            <div className=" mb-8">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Support
              </h2>
              <p className="mt-6 text-sm text-gray-500">
                Please describe in detail the issue you are having and we will
                get back to you shortly.
              </p>
            </div>

            <div className="overflow-hidden  rounded-lg py-4 border border-gray-300 shadow-sm">
              <label htmlFor="title" className="sr-only">
                Title
              </label>
              <input
                required
                type="text"
                name="title"
                id="title"
                value={data?.title}
                className="block w-full text-lg font-semibold resize-none border-0 rounded-lg outline-0 px-4 pb-6 pt-0 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                placeholder="Title..."
                onChange={(e) => setData({ ...data, title: e?.target?.value })}
              />
              <label htmlFor="description" className="sr-only">
                Description
              </label>
              <textarea
                required
                rows={8}
                name="description"
                id="description"
                value={data?.description}
                className="block w-full resize-none border-0 mb-14 rounded-lg outline-0 p-4 py-0 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                placeholder="Write a description..."
                defaultValue={""}
                onChange={(e) =>
                  setData({ ...data, description: e?.target?.value })
                }
              />
            </div>
            <div className="absolute border-t border-gray-300 flex justify-end w-full p-4 mt-12 inset-x-px bottom-0">
              <div className="flex-shrink-0">
                <LoadingButton
                  loading={sending}
                  type="submit"
                  variant="contained">
                  Submit
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </Layout>
  );
}
