import emailjs from "@emailjs/browser";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import Layout from "fragments/layout/layout";
import MuiPhoneNumber from "mui-phone-number";
import { useState } from "react";
import { toast } from "react-toastify";


export default function Contact() {
  const serviceKey = process.env.REACT_APP_EMAILJS_SERVICE_CODE;
  const templateKey = process.env.REACT_APP_EMAILJS_TEMPLATE_KEY;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const [sending, setSending] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendMail = async () => {
    if (
      data?.firstName?.length < 2 ||
      data?.lastName?.length < 2 ||
      data?.email?.length < 2 ||
      data?.phone?.length < 5 ||
      data?.message?.length < 3
    ) {
      toast?.error("Kindly fill the form.");
      return;
    } else {
      setSending(true);
      await emailjs
        .send(
          serviceKey,
          templateKey,
          {
            from_name: `${data?.firstName} ${data.lastName}`,
            phone: data?.phone,
            from_email: data?.email,
            message: data?.message,
          },
          publicKey
        )
        .then((res) => {
          toast.success(
            "Successfully Sent. Our representatives will contact you soon."
          );
          setData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.success("Error Submiting.");
        });
      setSending(false);
    }
  };

  return (
    <Layout title="Contact Us">
      <div className="my-24">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Contact us
          </h2>
          {/* <p className="mt-2 text-lg leading-8 text-gray-600">
            Contact us for any queries. 
          </p> */}
        </div>
        <div className="mx-auto mt-16 max-w-xl sm:mt-20">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <TextField
              label="First Name"
              type="first name"
              value={data?.firstName}
              onChange={(e) =>
                setData({ ...data, firstName: e?.target?.value })
              }
            />
            <div>
              <TextField
                label="Last Name"
                type="last name"
                value={data?.lastName}
                onChange={(e) =>
                  setData({ ...data, lastName: e?.target?.value })
                }
              />
            </div>
            <div className="sm:col-span-2">
              <TextField
                label="Email"
                value={data?.email}
                onChange={(e) => setData({ ...data, email: e?.target?.value })}
              />
            </div>
            <div className="sm:col-span-2">
              <MuiPhoneNumber
                defaultCountry={"us"}
                variant="outlined"
                label="Phone"
                value={data?.phone}
                onChange={(value) => setData({ ...data, phone: value })}
              />
            </div>
            <div className="sm:col-span-2">
              <TextField
                label="Message"
                value={data?.message}
                onChange={(e) =>
                  setData({ ...data, message: e?.target?.value })
                }
                multiline
                minRows={6}
                maxRows={10}
              />
            </div>
          </div>
          <div className="mt-10">
            <LoadingButton
              onClick={() => sendMail()}
              loading={Boolean(sending)}
              fullWidth
              variant="contained"
              type="submit">
              Let's talk
            </LoadingButton>
          </div>
        </div>
      </div>
    </Layout>
  );
}
