import { primaryFeatures } from "data/home";
import React from "react";

export function Features() {
  return (
    <div className="mx-auto mt-16 max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="mt-2 text-3xl font-bold tracking-tight text-grottoBlue sm:text-4xl">
          Key Features
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl className="grid max-w-xl grid-cols-1 gap-10 lg:max-w-none lg:grid-cols-3">
          {primaryFeatures.map((feature) => (
            <div key={feature.name} className="flex flex-col shadow-1 p-6 rounded-xl">
              <dt className="text-lg font-semibold leading-7 text-grottoBlue">
                <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-turqoise">
                  <feature.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                {feature.name}
              </dt>
              <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-grottoBlue/60">
                <p className="flex-auto">{feature.description}</p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
