import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Container } from "components/common/container";
import Loader from "components/loader";
import Viewer from "components/markdownEditor/viewer";
import { wellVisits } from "data/home";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { BiError, BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import { useVisit } from "./useVisit";

// mui
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

import ErrorMsg from "components/common/error";
import ReactQuillEditorOld from "components/markdownEditor/editor-quill";
import MarkdownIt from "markdown-it";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Joyride from "react-joyride";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import TurndownService from "turndown";
import { formateDate } from "utils";
import { BeaconComponent, TooltipJoy } from "./components/intro.visit";
const turndownService = new TurndownService();

function Visit() {
  const {
    sickVisitTemplates,
    visit,
    visitId,
    isRecording,
    stopRecording,
    startRecording,
    handleGenerateEMR,
    handleSubmitVisit,
    transcription,
    generatingEMR,
    submiting,
    encounters,
    clearTranscription,
    handleNewVisit,
    loading,
    handleChange,
    status,
    error,
    steps,
    user,
    handleFetchTodayEncounters,
    handleJoyrideCallback,
  } = useVisit();
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const md = new MarkdownIt();

  const textFieldRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom whenever text changes
    if (textFieldRef.current) {
      const textField = textFieldRef.current;
      textField.scrollTop = textField.scrollHeight - textField.clientHeight;
    }
  }, [visit]);

  return (
    <RouteGuard>
      <Layout
        title={
          visit?.patient?.first_name && visitId
            ? `${visit?.patient?.first_name} ${
                visit?.patient?.last_name
              } | ${formateDate(visit?.entry_date)} | DOB: ${formateDate(
                visit?.patient?.dob
              )} `
            : "Visit"
        }>
        {error !== "getVisitDetails" ? (
          <>
            {sickVisitTemplates && visit ? (
              <div className="relative isolate overflow-hidden">
                <svg
                  className="absolute inset-0 -z-10 h-full w-full stroke-grottoBlue/5 [mask-image:radial-gradient(100%_100%_at_top_right,babyBlue,transparent)]"
                  aria-hidden="true">
                  <defs>
                    <pattern
                      id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                      width={200}
                      height={200}
                      x="50%"
                      y={-1}
                      patternUnits="userSpaceOnUse">
                      <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                  </defs>
                  <svg
                    x="50%"
                    y={-1}
                    className="overflow-visible fill-babyBlue/5">
                    <path
                      d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                      strokeWidth={0}
                    />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                  />
                </svg>

                <div
                  className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                  aria-hidden="true">
                  <div
                    className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff]/60 to-[#4e46e5a3]/60 opacity-20"
                    style={{
                      clipPath:
                        "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                    }}
                  />
                </div>
                <Container>
                  <Drawer
                    onClose={() => setOpenSidePanel(false)}
                    sx={{
                      width: 300,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: 300,
                        boxSizing: "border-box",
                        borderRadius: "15px",
                        margin: "10px",
                        height: "97%",
                        "&::-webkit-scrollbar": {
                          width: "0px",
                        },
                      },
                    }}
                    // variant="persistent"
                    anchor="left"
                    open={openSidePanel}>
                    <div className="p-4">
                      <h2 className="text-2xl mb-4 font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        Encounters
                      </h2>
                      <div>
                        {encounters ? (
                          <>
                            {encounters?.length > 0 ? (
                              <>
                                {encounters?.map((encounter) => (
                                  <div
                                    className={`p-2 my-2 ${
                                      visit?.id == encounter?.id &&
                                      "bg-slate-200"
                                    } hover:bg-slate-200 w-full rounded-lg cursor-pointer`}>
                                    <Link
                                      onClick={() => setOpenSidePanel(false)}
                                      to={`/visit/${encounter?.id}`}
                                      key={encounter?.id}>
                                      <div className="w-full text-sm flex flex-col gap-">
                                        <div className="grid grid-cols-2">
                                          <p className="font-semibold">
                                            Patient Name
                                          </p>
                                          <p>
                                            {encounter?.patient?.first_name}{" "}
                                            {encounter?.patient?.last_name}
                                          </p>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <p className="font-semibold">DOB</p>
                                          <p>
                                            {formateDate(
                                              encounter?.patient?.dob
                                            )}
                                          </p>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <p className="font-semibold">
                                            Entry Date
                                          </p>
                                          <p>
                                            {formateDate(encounter?.entry_date)}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                                <BiError
                                  style={{ fontSize: "3rem", opacity: "0.4" }}
                                />
                                No Encounter found.
                              </div>
                            )}
                          </>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>
                  </Drawer>
                  {/* <Main open={openSidePanel}> */}
                  <div className="w-full flex justify-center">
                    <div className="w-full mb-[2rem]">
                      {/* <div className="max-w-[1000px] w-full my-[2rem]"> */}
                      <div className="my-4 flex justify-between">
                        <Button
                          onClick={() => setOpenSidePanel(!openSidePanel)}>
                          Schedule
                        </Button>
                        <Link to="/visit" onClick={() => handleNewVisit()}>
                          <Button>New Visit</Button>
                        </Link>
                      </div>
                      <div className="px-4 py-2 bg-white rounded-lg shadow">
                        <div className="">
                          <p className="block text-lg font-semibold text-grottoBlue">
                            Enter Patient's Info
                          </p>
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full sm:py-2">
                            <TextField
                              id="firstName"
                              size="small"
                              label="Patient's first name"
                              value={visit?.patient?.first_name || ""}
                              onChange={(e) => {
                                handleChange(e?.target?.value, "first_name");
                                // handleAutoSave();
                              }}
                            />
                            <TextField
                              id="lastName"
                              size="small"
                              label="Patient's last name"
                              value={visit?.patient?.last_name || ""}
                              onChange={(e) => {
                                handleChange(e?.target?.value, "last_name");
                                // handleAutoSave();
                              }}
                            />
                            <TextField
                              size="small"
                              type="date"
                              label="Patient's DOB"
                              value={moment(visit?.patient?.dob || "").format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                handleChange(e?.target?.value, "dob");
                                // handleAutoSave();
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                              size="small"
                              type="datetime-local"
                              label="Appointment Date"
                              value={visit?.entry_date || ""}
                              onChange={(e) => {
                                handleChange(e?.target?.value, "entry_date");
                                // handleAutoSave();
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <p className="block text-lg font-semibold text-grottoBlue">
                            Select a Template
                          </p>
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 sm:py-2 mb-1 gap-4 w-full">
                            <FormControl fullWidth id="template">
                              <InputLabel size="small" id="encounters">
                                Encounters
                              </InputLabel>
                              <Select
                                size="small"
                                labelId="template-encounters"
                                id="template-encounters"
                                value={visit?.template}
                                input={
                                  <OutlinedInput
                                    size="small"
                                    id="template-encounters"
                                    label="Encounters"
                                  />
                                }
                                onChange={(e) => {
                                  handleChange(e?.target?.value, "template");
                                  // handleAutoSave();
                                }}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                      bgcolor: selected ? "blue.200" : "",
                                    }}>
                                    {visit?.template?.title}
                                  </Box>
                                )}>
                                {sickVisitTemplates?.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {`${item?.title}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {/* redundant */}
                            <FormControl>
                              <InputLabel
                                size="small"
                                id="Well Visits (Female)">
                                Well Visits (Female)
                              </InputLabel>
                              <Select
                                size="small"
                                labelId="Well Visits (Female)"
                                label="Well Visits (Female)"
                                sx={{ maxHeight: "300px" }}
                                // onChange={(e) =>
                                //   setVisit({
                                //     ...visit,
                                //     template: e?.target?.value,
                                //   })
                                // }
                                value={visit?.template}>
                                <div className="max-h-[300px]">
                                  {wellVisits
                                    .map((opt) => ({
                                      title: opt,
                                    }))
                                    ?.map((item) => (
                                      <MenuItem value={item}>
                                        {item?.title}
                                      </MenuItem>
                                    ))}
                                </div>
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel size="small" id="Well Visits (Male)">
                                Well Visits (Male)
                              </InputLabel>
                              <Select
                                size="small"
                                labelId="Well Visits (Male)"
                                label="Well Visits (Male)"
                                // onChange={(e) =>
                                //   setVisit({
                                //     ...visit,
                                //     template: e?.target?.value,
                                //   })
                                // }
                                sx={{ maxHeight: "300px" }}
                                value={visit?.template}>
                                <div className="max-h-[300px]">
                                  {wellVisits
                                    .map((opt) => ({
                                      title: opt,
                                    }))
                                    ?.map((item) => (
                                      <MenuItem value={item}>
                                        {item?.title}
                                      </MenuItem>
                                    ))}
                                </div>
                              </Select>
                            </FormControl>
                            {/* redundant */}
                          </div>
                        </div>
                      </div>

                      <div className="grid md:grid-cols-2 gap-4">
                        <div>
                          {/* Recorder */}
                          <div className="flex w-full justify-center">
                            <div className="p-4  pt-8 flex flex-col items-center">
                              <p className="mb-1 font-semibold block text-lg text-grottoBlue">
                                Audio Recorder
                              </p>
                              {loading == "startingRecording" ? (
                                <Tooltip title="Starting Recording">
                                  <div className="flex p-4 text-4xl text-white cursor-pointer bg-grottoBlue shadow-md rounded-full">
                                    <Loader />
                                  </div>
                                </Tooltip>
                              ) : (
                                <div id="recorder">
                                  {isRecording ? (
                                    <Tooltip title="Stop Recording">
                                      <div
                                        onClick={() => stopRecording()}
                                        className="flex p-4 text-4xl text-white cursor-pointer bg-sienna shadow-md rounded-full">
                                        <BiMicrophoneOff />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Start Recording">
                                      <div
                                        onClick={() => startRecording()}
                                        className="flex p-4 text-4xl text-white cursor-pointer bg-turqoise shadow-md rounded-full">
                                        <BiMicrophone />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Live Transcription */}
                          <div className="divide-y my-6 bg-white divide-gray-200 overflow-hidden rounded-lg shadow">
                            <div className="p-4 sm:px-6">
                              <p className="-mb-2 block text-lg font-medium text-grottoBlue">
                                Live transcription
                              </p>
                              <p className="text-red-500 text-sm">
                                Start recording to begin transcription
                              </p>
                            </div>
                            <div className="px-4 py-5 sm:p-6">
                              <div
                                data-color-mode="light"
                                className="border text-[14px] p-2 rounded-lg h-[400px] overflow-y-scroll"
                                ref={textFieldRef}>
                                <p>
                                  {visitId
                                    ? `${visit?.transcription || " "} ${
                                        transcription || " "
                                      }`
                                    : visit?.transcription || ""}
                                </p>
                              </div>
                              <div className="my-4 float-right">
                                <LoadingButton
                                  disabled={isRecording}
                                  size="medium"
                                  fullWidth="false"
                                  variant="contained"
                                  onClick={() => clearTranscription()}>
                                  Clear
                                </LoadingButton>
                              </div>
                            </div>
                          </div>

                          {/* Clinical Board */}
                          <div className="divide-y my-6 bg-white divide-gray-200 overflow-hidden rounded-lg shadow">
                            <div className="p-4 sm:px-6">
                              <p className="-mb-2 block text-lg font-medium text-grottoBlue">
                                Clinical Clipboard
                              </p>
                              <p className="text-red-500 text-sm">
                                Select a template
                              </p>
                            </div>
                            <div className="px-4 py-5 sm:p-6">
                              <div
                                data-color-mode="light"
                                className="border rounded-lg">
                                <Viewer
                                  value={visit?.template?.physician_note}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* EMR Entry */}
                        <div className="flex flex-col items-center mt-8">
                          <div className="my-4 float-right" id="generateEMR">
                            <LoadingButton
                              disabled={isRecording}
                              loading={generatingEMR}
                              size="medium"
                              fullWidth="false"
                              variant="contained"
                              onClick={() => handleGenerateEMR()}>
                              Generate EMR
                            </LoadingButton>
                          </div>
                          <div className="divide-y w-full my-6 h-full bg-white divide-gray-200 overflow-hidden rounded-lg shadow">
                            <div className="p-4 sm:px-6 flex justify-between items-center">
                              <div className="">
                                <p className="-mb-2 block text-lg font-medium text-grottoBlue">
                                  EMR Entry
                                </p>
                                <p className="text-red-500 text-sm">
                                  Record a patient encounter and then press
                                  Generate EMR Button.
                                </p>
                              </div>
                            </div>
                            <div className="px-4 py-5 sm:p-6">
                              <div>
                                <ReactQuillEditorOld
                                  // height="50vh"
                                  height="100%"
                                  text={md.render(visit?.emr?.note || " ")}
                                  fireOnChange={false}
                                  onChange={(value) => {
                                    handleChange(
                                      turndownService.turndown(value),
                                      "emr"
                                    );
                                    // console.log(value)
                                    // handleAutoSave();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="float-right fixed bottom-0 right-0 mt-4 m-8 ">
                        <LoadingButton
                          sx={{ marginRight: "10px" }}
                          loading={submiting == "export"}
                          onClick={() => {
                            handleSubmitVisit({
                              type: "export",
                              _visit: {
                                ...visit,
                                transcription: `${visit?.transcription} ${transcription}`,
                              },
                            });
                          }}
                          variant="contained">
                          Export to EMR
                        </LoadingButton>
                        <LoadingButton
                          loading={submiting == "save"}
                          onClick={() =>
                            handleSubmitVisit({
                              type: "save",
                              _visit: {
                                ...visit,
                                transcription: `${visit?.transcription} ${transcription}`,
                              },
                            })
                          }
                          variant="contained">
                          Save
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                  {/* </Main> */}
                </Container>
                {status && (
                  <div className="fixed top-0 z-[9999] w-full flex justify-center">
                    <p className="bg-grottoBlue px-4 py-1 text-white text-sm shadow-sm rounded-b-md">
                      {status}
                    </p>
                  </div>
                )}
                <Joyride
                  steps={steps}
                  run={!Boolean(user?.introDone)}
                  continuous
                  showProgress
                  showSkipButton
                  scrollToFirstStep={true}
                  callback={handleJoyrideCallback}
                  tooltipComponent={TooltipJoy}
                  beaconComponent={BeaconComponent}
                />
              </div>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <div>
            <ErrorMsg text="No Visit found. Something went wrong." />
          </div>
        )}
        <Modal open={generatingEMR}>
          <div className="h-screen flex justify-center items-center">
            <div className="w-[200px] text-center shadow-1 rounded-lg p-6 bg-white">
              <h1 className="text-2xl font-semibold">Please Wait!</h1>
              <p className="mt-2 mb-4 text-gray-500">Generating EMR</p>
              <Loader />
            </div>
          </div>
        </Modal>
      </Layout>
    </RouteGuard>
  );
}

export default Visit;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${300}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);
