import { useAuthContext } from "contexts/authContext";
import AdminLayout from "fragments/admin/layout";
import RouteGuardAdmin from "hoc/routeGuardAdmin";
import { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  getGroupsAPI,
  getSingleTemplateAPI,
  getTemplateTypesAPI,
  updateTemplateAPI,
} from "apis";
import Loader from "components/loader";
import ReactQuillEditorOld from "components/markdownEditor/editor-quill";
import MarkdownIt from "markdown-it";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TurndownService from "turndown";
const turndownService = new TurndownService();

export default function UpdateTemplate() {
  const md = new MarkdownIt();
  const params = useParams();
  const { id } = params;
  const { admin, adminToken, handleSessionExpired } = useAuthContext();
  const theme = useTheme();
  const [template, setTemplate] = useState();

  const [groups, setGroups] = useState();
  const [templateType, setTemplateType] = useState();
  const [submitting, setSubmitting] = useState(false);

  const getTemplatestype = async () => {
    await getTemplateTypesAPI()
      .then((res) => {
        // console.log(res);
        setTemplateType(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const getTemplate = async () => {
    await getSingleTemplateAPI({ id })
      .then((res) => {
        // console.log(res);
        setTemplate({
          ...res?.data?.result,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const handleSubmitTemplate = async () => {
    setSubmitting(true);
    await updateTemplateAPI({
      template: {
        group: template?.group?.map((group) => group?.id),
        type: template?.type?.id,
        title: template?.title,
        physician_note: template?.physician_note,
        text: template?.text,
      },
      template_id: id,
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Updating Template.");
        } else {
          toast?.success("Successfully Update Template.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setSubmitting(false);
  };

  const handleSelectChange = (e) => {
    const selectedOption = templateType.find(
      (option) => option.id === e.target.value
    );
    setTemplate({
      ...template,
      type: selectedOption,
    });
  };

  const getGroups = async () => {
    await getGroupsAPI()
      .then((res) => {
        // console.log(res);
        setGroups(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const handleChange = (event) => {
    const selectedIds = event.target.value || [];
    const selectedGroups = groups.filter((group) =>
      selectedIds.includes(group.id)
    );
    setTemplate({
      ...template,
      group: selectedGroups, // Store selected group objects in the state
    });
  };

  useEffect(() => {
    getTemplatestype();
    getTemplate();
    getGroups();
  }, [id]);

  // console.log(template);
  // console.log(templateType);

  return (
    <RouteGuardAdmin>
      <AdminLayout>
        <div className="m-5">
          <div className="">
            <h1 className="text-2xl">Update Template</h1>
          </div>
          {templateType && template && groups ? (
            <div>
              <div className="mt-8">
                <div className="mb-4">
                  <p className="mb-1 block text-lg font-semibold text-grottoBlue">
                    Template Info
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full py-4">
                    <TextField
                      label="Template Title"
                      value={template?.title || ""}
                      onChange={(e) =>
                        setTemplate({
                          ...template,
                          title: e?.target?.value,
                        })
                      }
                    />
                    <FormControl>
                      <InputLabel id="template-type">Template Type</InputLabel>
                      <Select
                        labelId="template-type"
                        label="Template Type"
                        onChange={(e) => handleSelectChange(e)}
                        value={template?.type?.id || ""}>
                        {templateType?.map((item, i) => (
                          <MenuItem value={item?.id} key={i}>
                            {item?.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* 
                    <FormControl>
                      <InputLabel id="template-group">Groups</InputLabel>
                      <Select
                        labelId="template-group"
                        id="template-group"
                        multiple
                        value={template?.group || []}
                        input={
                          <OutlinedInput id="template-group" label="Groups" />
                        }
                        onChange={(e) =>
                          setTemplate({
                            ...template,
                            group:
                              typeof e?.target?.value === "string"
                                ? e?.target?.value.split(",")
                                : e?.target?.value,
                          })
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}>
                            {template?.group?.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}>
                        {groups.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, template?.group, theme)}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

                    <FormControl fullWidth>
                      <InputLabel id="template-group">Groups</InputLabel>
                      <Select
                        labelId="template-group"
                        id="template-group"
                        multiple
                        value={template?.group?.map((group) => group?.id) || []}
                        input={
                          <OutlinedInput id="template-group" label="Groups" />
                        }
                        onChange={handleChange}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}>
                            {template?.group?.map((group) => (
                              <Chip key={group?.id} label={group?.name} />
                            ))}
                          </Box>
                        )}>
                        {groups?.map(({ id, name, type }) => (
                          <MenuItem key={id} value={id}>
                            {`${name} (${type})`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="mb-4">
                  <p className="mb-1 block text-lg font-semibold text-grottoBlue">
                    Template's Text
                  </p>
                  <div className="w-full py-4">
                    <ReactQuillEditorOld
                      height="50vh"
                      text={md.render(template?.text || " ")}
                      onChange={(value) =>
                        setTemplate({
                          ...template,
                          text: turndownService.turndown(value),
                        })
                      }
                    />
                  </div>
                </div>

                <div className="mt-24">
                  <p className="mb-1 block text-lg font-semibold text-grottoBlue">
                    Physician's Note
                  </p>
                  <div className="w-full py-4">
                    <ReactQuillEditorOld
                      height="50vh"
                      text={md.render(template?.physician_note || " ")}
                      onChange={(value) =>
                        setTemplate({
                          ...template,
                          physician_note: turndownService.turndown(value),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="my-12 float-right">
                <LoadingButton
                  loading={submitting}
                  onClick={() => handleSubmitTemplate()}
                  variant="contained">
                  Save Template
                </LoadingButton>
              </div>
            </div>
          ) : (
            <div className="h-[50vh]">
              <Loader />
            </div>
          )}
        </div>
      </AdminLayout>
    </RouteGuardAdmin>
  );
}

const groups = ["speciality", "clinic", "global"];

function getStyles(name, title, theme) {
  return {
    fontWeight:
      title.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
