import { LoadingButton } from "@mui/lab";
import { Avatar, TextField } from "@mui/material";
import { updateUserInfoAPI } from "apis";
import Loader from "components/loader";
import { useAuthContext } from "contexts/authContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { setLocalData } from "utils/storage";

function General() {
  const { user, handleSessionExpired } = useAuthContext();

  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await updateUserInfoAPI({
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      phone_number: userData?.phone_number,
      organization_name: userData?.organization_name,
      physicians_count: userData?.physicians_count,
      organization_address: userData?.organization_address,
      electronic_medical_record: userData?.electronic_medical_record,
      how_did_you_learn: userData?.how_did_you_learn,
      special_request: userData?.special_request,
      practice_name: userData?.practice_name,
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Something went wrong.");
        } else {
          setLocalData(
            "@nz__user",
            JSON.stringify({
              ...user,
              ...userData,
              type: "email",
              photoURL: user?.photoURL,
            })
          );
          toast?.success(res?.data?.message || "Successfully updated.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status == 401) {
          handleSessionExpired();
        } else {
          toast.error(err?.response?.message || "Something went wrong.");
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    setUserData(user);
  }, [user]);

  return (
    <>
      {user ? (
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Profile
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            This information is provided by your email account provider.
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Profile Image
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <Avatar
                  sx={{ width: "60px", height: "60px" }}
                  src={userData?.photoURL}
                  alt=""
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                First name
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.first_name}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({ ...userData, first_name: e?.target?.value })
                  }
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Last name
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.last_name}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({ ...userData, last_name: e?.target?.value })
                  }
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Email address
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={user?.email}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  disabled
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Phone
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.phone_number}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({ ...userData, phone_number: e?.target?.value })
                  }
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Organization Name
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.organization_name}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      organization_name: e?.target?.value,
                    })
                  }
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Physicians Count
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  type="number"
                  value={userData?.physicians_count}
                  inputProps={{
                    min: "1",
                    step: "1",
                  }}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      physicians_count:
                        !isNaN(e.target.value) && parseInt(e.target.value) >= 1
                          && parseInt(e.target.value)
                    })
                  }
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Organization Address
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.organization_address}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      organization_address: e?.target?.value,
                    })
                  }
                />
              </dd>
            </div>

            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Electronic Medical Record
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.electronic_medical_record}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      electronic_medical_record: e?.target?.value,
                    })
                  }
                />
              </dd>
            </div>

            {/* <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                How did you learn about NotesZen
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.how_did_you_learn}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      how_did_you_learn: e?.target?.value,
                    })
                  }
                />
              </dd>
            </div> */}

            {/* <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Special Request
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.special_request}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      special_request: e?.target?.value,
                    })
                  }
                />
              </dd>
            </div> */}
            {/* <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Practice Name
              </dt>
              <dd className="mt-1 opacity-70 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <TextField
                  value={userData?.practice_name}
                  size="small"
                  variant="standard"
                  sx={{ width: "fitContent" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      practice_name: e?.target?.value,
                    })
                  }
                />
              </dd>
            </div> */}

            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                LoggedIn using
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{user?.type?.toUpperCase()}</div>
              </dd>
            </div>
          </dl>
          <div className="flex justify-end my-8 w-full">
            <LoadingButton
              onClick={() => handleSubmit()}
              loading={loading}
              variant="contained">
              Update
            </LoadingButton>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default General;
