import { Link } from "react-router-dom";
import "./index.css";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, IconButton } from "@mui/material";
import { COLORS } from "constants/colors.js";

import { removeAllCookies } from "utils/storage";

export default function AdminHeader({ onMenuClick, clicked }) {
  const handleLogout = () => {
    removeAllCookies();
    window?.location?.replace("/login");
  };

  return (
    <>
      <header className="header bg-grottoBlue">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div className="menuIcon">
            <IconButton
              className="menuIcon"
              sx={{ color: "white" }}
              onClick={() => onMenuClick()}>
              <MenuIcon sx={{ fontSize: "35px", color: COLORS.primary }} />
            </IconButton>
          </div>
          <div className="header_logo_mobile">
            <img
              src="/favicon.png"
              alt="noteszen logo"
              style={{ width: "40px", height: "40px", marginLeft: "10px" }}
            />
          </div>
        </Box>
        <div className="mr-2">
          <Link to="/">
            <Button variant="contained" onClick={() => handleLogout()}>
              LogOut
            </Button>
          </Link>
        </div>
      </header>
    </>
  );
}
