import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "utils/scrollToTop";

// pages
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NotFound from "pages/404";
import { Groups } from "pages/admin/groups";
import Templates from "pages/admin/templates";
import { AddTemplates } from "pages/admin/templates/add";
import UpdateTemplates from "pages/admin/templates/update";
import TemplateType from "pages/admin/templatesType";
import { ForgotPassword } from "pages/auth/forgotPassword";
import { Login } from "pages/auth/login";
import { OTP } from "pages/auth/otp";
import { ResetPassword } from "pages/auth/resetPassword";
import { SignUp } from "pages/auth/signup";
import Contact from "pages/contactus";
import FAQs from "pages/faqs";
import Home from "pages/home";
import { Pricing } from "pages/plans";
import { Schedule } from "pages/schedule";
import Settings from "pages/settings";
import PaymentSuccess from "pages/statusPages/paymentSuccess";
import { SessionExpired } from "pages/statusPages/sessionExpired";
import Support from "pages/support";
import Test from "pages/test";
import Trial from "pages/trial";
import Visit from "pages/visit";
import PatientProfile from "pages/patientProfile";

export default function App() {
  // console.log({
  //   apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  //   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  //   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  //   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  //   appId: process.env.REACT_APP_FIREBASE_APP_ID,
  //   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  //   REACT_APP_STRIP_PUBLISHABLE_KEY:
  //     process.env.REACT_APP_STRIP_PUBLISHABLE_KEY,

  //   serviceKey: process.env.REACT_APP_EMAILJS_SERVICE_CODE,
  //   templateKey: process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
  //   publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,

  //   serviceKey_support: process.env.REACT_APP_EMAILJS_SERVICE_CODE_FOR_SUPPORT,
  //   templateKey_support: process.env.REACT_APP_EMAILJS_TEMPLATE_KEY_FOR_SUPPORT,
  //   publicKey_support: process.env.REACT_APP_EMAILJS_PUBLIC_KEY_FOR_SUPPORT,

  //   azureSpeechKey: process.env.REACT_APP_AZURE_SPEECH_KEY,
  //   azureSpeechLang: process.env.REACT_APP_AZURE_SPEECH_LANG,
  //   azureSpeechLang_no_React_app: process.env.AZURE_SPEECH_LANG,
  // });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/trial" element={<Trial />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/support" element={<Support />} />

          {/* Auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/settings" element={<Settings />} />

          <Route path="/visit" element={<Visit />} />
          <Route path="/visit/:visitId" element={<Visit />} />
          <Route path="/schedule" element={<Schedule />} />

          <Route path="/patients/:id" element={<PatientProfile />} />

          {/* status pages */}
          <Route path="/session-expired" element={<SessionExpired />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

          <Route path="*" element={<NotFound />} />
          <Route path="/test" element={<Test />} />

          {/* Admin */}
          {/* Templates */}
          <Route path="/admin/templates" element={<Templates />} />
          <Route path="/admin/templates/:id" element={<UpdateTemplates />} />
          <Route path="/admin/templates/add" element={<AddTemplates />} />

          {/* Template types */}
          <Route path="/admin/templateTypes" element={<TemplateType />} />

          {/* Groups */}
          <Route path="/admin/groups" element={<Groups />} />
        </Routes>
        <ToastContainer hideProgressBar="true" />
      </LocalizationProvider>
    </>
  );
}
