import { Button, Dialog } from "@mui/material";
import { BiError } from "react-icons/bi";

export default function GeneralDialogue({
  open,
  onClose,
  payload,
  onCancel,
  onAgree,
}) {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <div className="text-center flex flex-col items-center w-full my-2 opacity-80">
              <BiError
                style={{ fontSize: "3rem", opacity: "1" }}
                className="text-grottoBlue"
              />
              <h3 className="text-2xl font-semibold text-center">
                {payload?.heading}
              </h3>
              <p>{payload?.text}</p>
            </div>
            <div className="flex w-full gap-2 pt-4 justify-center">
              <Button
                variant="outlined"
                onClick={() => onCancel()}
                className="bg-white text-red">
                No
              </Button>
              <Button onClick={() => onAgree()} variant="contained">
                Agree
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
