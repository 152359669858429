import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export function DropDown({ label, children, bodyClassName }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button as="div" className="">
          {label}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <div
          className={`absolute right-0 bg-white w-full shadow-1 p-2 rounded-lg border border-lightgray ${bodyClassName}`}>
          {children}
        </div>
      </Transition>
    </Menu>
  );
}
