import { useAuthContext } from "contexts/authContext";
import { Footer } from "fragments/footer";
import { Header } from "fragments/header";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingNotes } from "store/encounterSlice";

export default function Layout({ children, title }) {
  const { token } = useAuthContext();
  const dispatch = useDispatch();
  const { pendingNotesCount } = useSelector((state) => state.encounters);

  useEffect(() => {
    if (!pendingNotesCount && token) {
      dispatch(fetchPendingNotes());
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title>
          {pendingNotesCount ? `(${pendingNotesCount}) | ${title}` : title}
        </title>
      </Helmet>
      <Header />
      <div className="min-h-[50vh] mt-[32px]">{children}</div>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  title: "NotesZen",
  description: "robots",
  keywords: "noindex, nofollow",
};
