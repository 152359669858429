import React from "react";
import { Link } from "react-router-dom";

export default function AnimatedLink({ to, children, underlineColor, className }) {
  return (
    <Link to={to} className={`relative group  ${className}`}>
      {children}
      <span
        className={` absolute inset-x-0 bottom-0 h-0.5 bg-${underlineColor} transform origin-bottom-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100`}></span>
    </Link>
  );
}
