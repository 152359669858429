import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { COLORS } from "constants/colors.js";

// mui
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Box,
  Drawer,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getCookie, removeAllCookies } from "utils/storage";
import AdminHeader from "../header";

const drawerWidth = 280;

export default function AdminLayout({ children, title }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState(false);
  const handleSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  // adminName menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeAllCookies();
    // console.log("loggout called");
    if (typeof window !== "undefined") {
      window?.location?.replace("/login");
    }
  };

  const drawer = (
    <div className="drawer">
      <div className="logoContainer">
        <div className="m-2 block float-left min-[900px]:hidden">
          <IconButton onClick={handleSideBar} sx={{ color: COLORS.grottoBlue }}>
            <CloseIcon sx={{ fontSize: "35px", color: COLORS.primary }} />
          </IconButton>
        </div>
        {/* <div className="flex gap-2 items-center w-full justify-center max-[900px]:mr-14">
          <img alt="" src="/favicon.png" className="w-10" />
          <Typography sx={{ color: COLORS.yellow, fontWeight: "bold" }}>
            NotesZen
          </Typography>
        </div> */}
      </div>
      <div className="flex flex-col justify-center w-full items-center mt-10">
        <div className="border-solid border-2 border-gray_light rounded-full p-1">
          <div className="border-solid border-4 border-gray_light rounded-full">
            <Avatar
              sx={{
                width: "70px",
                height: "70px",
                backgroundColor: COLORS.grottoBlue,
                margin: "5px",
              }}
            />
          </div>
        </div>
        <div className="flex cursor-pointer" onClick={handleClick}>
          <Typography sx={{ fontWeight: "bold", color: COLORS.grottoBlue }}>
            Admin
          </Typography>
          {anchorEl ? (
            <ArrowDropUpIcon sx={{ color: COLORS.grottoBlue }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: COLORS.grottoBlue }} />
          )}
        </div>
        <Typography className="opacity-50 italic">admin@gmail.com</Typography>
      </div>
      <Menu
        TransitionComponent={Fade}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            // backgroundColor: COLORS.indigo,
            color: COLORS.grottoBlue,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            mt: 1.5,
            width: "200px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <Link to="">
          <MenuItem onClick={handleClose}>
            <PersonIcon sx={{ margin: "0 15px 0 0 " }} />
            <Typography>My Profile</Typography>
          </MenuItem>
        </Link>
        <div onClick={() => handleLogout()}>
          <MenuItem>
            <LogoutIcon sx={{ margin: "0 15px 0 0 " }} />
            <Typography>Logout</Typography>
          </MenuItem>
        </div>
      </Menu>
      <div className="flex flex-col gap-2 justify-center items-center mt-8">
        <Link to="/admin/templates">
          <div
            className={`flex gap-3 items-center w-60 hover:bg-yellow transition-all px-4 py-4 rounded-xl
            ${
              location.pathname.includes("/admin/templates")
                ? "bg-grottoBlue text-white"
                : "bg-white text-grottoBlue"
            }
            `}>
            <DashboardIcon
              sx={
                location.pathname.includes("/admin/templates")
                  ? {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }
                  : {
                      color: COLORS.primary,
                      cursor: "pointer",
                    }
              }
            />
            <Typography
              sx={
                location.pathname.includes("/admin/templates")
                  ? {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }
                  : {
                      color: COLORS.primary,
                      cursor: "pointer",
                    }
              }>
              Templates
            </Typography>
          </div>
        </Link>
      </div>

      <div className="flex flex-col gap-2 justify-center items-center">
        <Link to="/admin/templateTypes">
          <div
            className={`flex gap-3 items-center w-60 hover:bg-yellow transition-all px-4 py-4 rounded-xl
            ${
              location.pathname.includes("/admin/templateTypes")
                ? "bg-grottoBlue text-white"
                : "bg-white text-grottoBlue"
            }
            `}>
            <DashboardIcon
              sx={
                location.pathname.includes("/admin/templateTypes")
                  ? {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }
                  : {
                      color: COLORS.primary,
                      cursor: "pointer",
                    }
              }
            />
            <Typography
              sx={
                location.pathname.includes("/admin/templateTypes")
                  ? {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }
                  : {
                      color: COLORS.primary,
                      cursor: "pointer",
                    }
              }>
              Template Types
            </Typography>
          </div>
        </Link>
      </div>

      <div className="flex flex-col gap-2 justify-center items-center">
        <Link to="/admin/groups">
          <div
            className={`flex gap-3 items-center w-60 hover:bg-yellow transition-all px-4 py-4 rounded-xl
            ${
              location.pathname.includes("/admin/groups")
                ? "bg-grottoBlue text-white"
                : "bg-white text-grottoBlue"
            }
            `}>
            <DashboardIcon
              sx={
                location.pathname.includes("/admin/groups")
                  ? {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }
                  : {
                      color: COLORS.primary,
                      cursor: "pointer",
                    }
              }
            />
            <Typography
              sx={
                location.pathname.includes("/admin/groups")
                  ? {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    }
                  : {
                      color: COLORS.primary,
                      cursor: "pointer",
                    }
              }>
              Groups
            </Typography>
          </div>
        </Link>
      </div>
    </div>
  );

  // const container =
  //   window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    const admin = getCookie("@nz__l");
    if (!admin) {
      // navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="shortcut icon"
          href={location.pathname !== "/" && "/favicon.png"}
          type="image/x-icon"
        />
        <title>{title}</title>
      </Helmet>
      <AdminHeader onMenuClick={handleSideBar} />
      <div className="flex">
        <Box aria-label="mailbox folders">
          <Drawer
            // container={container}
            variant="temporary"
            open={showSideBar}
            onClose={handleSideBar}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderRadius: "15px",
                // overflow: "hidden",
                margin: "10px",
                height: "97%",
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
              },
            }}>
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              overflow: "hidden",
              borderRadius: "10px",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open>
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ width: "100%", marginTop: "3.5rem" }}>
          <div className="main_children">{children}</div>
        </Box>
      </div>
    </>
  );
}

AdminLayout.defaultProps = {
  title: "NotesZen Admin Panel",
  description: "robots",
  keywords: "noindex, nofollow",
};
