import { Link } from "react-router-dom";
import { YoutubeEmbed } from "../../../../components/youtubeEmbed/index";
import { Button } from "@mui/material";

function Hero() {
  return (
    <div className="relative isolate overflow-hidden">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-grottoBlue/5 [mask-image:radial-gradient(100%_100%_at_top_right,babyBlue,transparent)]"
        aria-hidden="true">
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-babyBlue/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <div
        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        aria-hidden="true">
        <div
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
        />
      </div>

      <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 lg:mt-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left">
            <h1 className="max-w-2xl whitespace-wrap lg:whitespace-nwrap text-4xl font-bold tracking-tight text-grottoBlue sm:text-6xl lg:col-span-2 xl:col-auto">
              The Future of Notes: <br />
              Fast, Accurate, Burnout-Free
            </h1>
            <p className="mt-6 text-xl leading-8 text-grottoBlue/60">
              Experience more patient time and less screen time with NotesZen,
              the only intelligent medical scribe designed for pediatricians.
            </p>
            <div className="flex justify-center lg:justify-start sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
              <div className="my-10 flex items-center gap-x-6">
                <Link to="/login">
                  <Button
                    variant="contained"
                    // size="large"
                    className="bg-turqoise text-white text-lg p-6 w-[250px]">
                    Get Started
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">
            <div className="relative mx-auto flex justify-center w-full">
              <div className="relative sm:block hidden rounded-lg overflow-hidden">
                <YoutubeEmbed
                  // className="bottom-0 lg:bottom-[80px] "
                  embedId="a2h4AXniF7E?si=Gx0UZbfDGkX0HyJT"
                  className=""
                  width="600"
                  height="338"
                />
              </div>
              <div className="block sm:hidden lg:hidden rounded-lg overflow-hidden">
                <YoutubeEmbed
                  embedId="a2h4AXniF7E?si=Gx0UZbfDGkX0HyJT"
                  className="mt-10 aspect-[6/3.5] w-full rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                  width="442"
                  height="250"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Hero;
