import React from "react";

function Test() {
  return (
    <>
      <p className="text-4xl">This is a test page.</p>
    </>
  );
}

export default Test;
