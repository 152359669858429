import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  addGroupAPI,
  addTemplateTypeAPI,
  deleteGroupAPI,
  deleteTemplateTypeAPI,
  getGroupsAPI,
  getTemplateTypesAPI,
  updateGroupAPI,
  updateTemplateTypeAPI,
} from "apis";
import Loader from "components/loader";
import { COLORS } from "constants/colors";
import { useAuthContext } from "contexts/authContext";
import AdminLayout from "fragments/admin/layout";
import RouteGuardAdmin from "hoc/routeGuardAdmin";
import { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { FaEllipsisH } from "react-icons/fa";
import { toast } from "react-toastify";

export function Groups() {
  const [groups, setGroups] = useState();
  const { admin, adminToken, handleSessionExpired } = useAuthContext();
  const [anchorElArray, setAnchorElArray] = useState([]);
  const [groupToUpdate, setGroupToUpdate] = useState(null);
  const [addGroup, setAddGroup] = useState(null);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGroups = async () => {
    await getGroupsAPI()
      .then((res) => {
        console.log(res);
        setGroups(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
  };

  const handleUpdateGroup = async () => {
    setLoading(true);
    await updateGroupAPI({
      group: {
        name: groupToUpdate?.name,
        type: groupToUpdate?.type,
      },
      group_id: groupToUpdate?.id,
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Updating Group.");
        } else {
          handleUpdateGroupInState();
          handleClose();
          toast?.success("Successfully Update Group.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleUpdateGroupInState = () => {
    setGroups((prevArray) => {
      const index = prevArray.findIndex((obj) => obj.id === groupToUpdate.id);

      if (index !== -1) {
        // Update the object at the found index
        const newArray = [...prevArray];
        newArray[index] = { ...newArray[index], ...groupToUpdate };
        return newArray;
      }

      return prevArray;
    });
  };

  const handleAddGroup = async () => {
    setLoading(true);
    await addGroupAPI({
      name: addGroup?.name,
      type: addGroup?.type,
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Adding Group.");
        } else {
          setGroups([...groups, res?.data?.result?.group]);
          setAddGroup(null);
          toast?.success("Successfully Added Group.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleDeleteGroup = async () => {
    setLoading(true);
    await deleteGroupAPI(groupToDelete?.id)
      .then((res) => {
        console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(res?.data?.message || "Error Deleting Group.");
        } else {
          handleDeleteTypeInState();
          setGroupToDelete(null);
          toast?.success("Successfully Deleted Group.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          handleSessionExpired();
        }
      });
    setLoading(false);
  };

  const handleDeleteTypeInState = () => {
    setGroups((prevArray) =>
      prevArray.filter((obj) => obj.id !== groupToDelete?.id)
    );
  };

  const handleMenuClick = (event, objectId) => {
    setAnchorElArray((prevArray) => [
      ...prevArray,
      { id: objectId, anchorEl: event.currentTarget },
    ]);
    setGroupToUpdate(null);
  };

  const handleClose = (objectId) => {
    setAnchorElArray((prevArray) =>
      prevArray.filter((item) => item.id !== objectId)
    );
    setGroupToUpdate(null);
  };

  const handleMenuItemClick = (action, objectId, group) => {
    handleClose(objectId);
    if (action == "Edit") {
      setGroupToUpdate(group);
    } else if (action == "Delete") {
      setGroupToDelete(group);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <RouteGuardAdmin>
      <AdminLayout>
        <div className="m-5">
          <div className="flex justify-between">
            <h1 className="text-2xl">Groups</h1>
            <Button onClick={() => setAddGroup({})}>Add</Button>
          </div>

          <div className="h-400 mt-5">
            {groups ? (
              <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                {groups.map((group, i) => (
                  <li
                    key={group.id}
                    className="col-span-1 flex rounded-lg shadow-sm">
                    <div className="flex flex-1 items-center justify-between rounded-lg truncate rounded-r-md border border-gray-200 bg-white">
                      <div className="flex-1 truncate px-4 py-2 text-sm">
                        <p className="font-medium text-gray-900 hover:text-gray-600">
                          {group.name}
                        </p>
                        <p className="text-gray-500">{group.type}</p>
                      </div>
                      <div className="flex-shrink-0 pr-2">
                        <IconButton
                          aria-label="more"
                          aria-controls={`menu-${i}`}
                          aria-haspopup="true"
                          onClick={(event) => handleMenuClick(event, i)}
                          sx={{ color: COLORS?.grottoBlue }}>
                          <FaEllipsisH className="h-5 w-5" aria-hidden="true" />
                        </IconButton>
                        <Menu
                          id={`menu-${i}`}
                          anchorEl={
                            anchorElArray.find((item) => item.id === i)
                              ?.anchorEl
                          }
                          open={Boolean(
                            anchorElArray.find((item) => item.id === i)
                          )}
                          onClose={() => handleClose(i)}>
                          <MenuItem
                            onClick={() =>
                              handleMenuItemClick("Edit", i, group)
                            }>
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleMenuItemClick("Delete", i, group)
                            }>
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="h-[50vh]">
                <Loader />
              </div>
            )}
          </div>

          {/* To Update Group */}
          <Dialog
            open={Boolean(groupToUpdate)}
            onClose={() => setGroupToDelete(null)}>
            <DialogTitle>Update Group</DialogTitle>
            <DialogContent>
              <div className="mt-4">
                <TextField
                  value={groupToUpdate?.name}
                  autoFocus
                  label="Group Name"
                  variant="outlined"
                  onChange={(e) =>
                    setGroupToUpdate({
                      ...groupToUpdate,
                      name: e?.target?.value,
                    })
                  }
                />
                <FormControl fullWidth sx={{ marginTop: "10px" }}>
                  <InputLabel id="group-type">Group Type</InputLabel>
                  <Select
                    // fullWidth
                    labelId="group-type"
                    label="Group Type"
                    onChange={(e) =>
                      setGroupToUpdate({
                        ...groupToUpdate,
                        type: e?.target?.value,
                      })
                    }
                    value={groupToUpdate?.type || ""}>
                    {groupTypes?.map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleUpdateGroup()}>
                Update
              </LoadingButton>
            </DialogActions>
          </Dialog>

          {/* To Add New Group */}
          <Dialog open={Boolean(addGroup)} onClose={() => setAddGroup(null)}>
            <DialogTitle>Add Group</DialogTitle>
            <DialogContent>
              <div className="mt-4">
                <TextField
                  value={addGroup?.name}
                  autoFocus
                  label="Group Name"
                  variant="outlined"
                  onChange={(e) =>
                    setAddGroup({
                      ...addGroup,
                      name: e?.target?.value,
                    })
                  }
                />
                <FormControl fullWidth sx={{ marginTop: "10px" }}>
                  <InputLabel id="group-type">Group Type</InputLabel>
                  <Select
                    // fullWidth
                    labelId="group-type"
                    label="Group Type"
                    onChange={(e) =>
                      setAddGroup({
                        ...addGroup,
                        type: e?.target?.value,
                      })
                    }
                    value={addGroup?.type || ""}>
                    {groupTypes?.map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
              <Button variant="outlined" onClick={() => setAddGroup(null)}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleAddGroup()}>
                Add
              </LoadingButton>
            </DialogActions>
          </Dialog>

          {/* To Delete Group */}
          <Dialog
            open={Boolean(groupToDelete)}
            onClose={() => setGroupToDelete(null)}>
            <DialogTitle>Delete Group</DialogTitle>
            <DialogContent>
              <div className="flex gap-2 items-center">
                <BiError className="text-red-600 text-3xl" />
                Confirm to Delete Group{" "}
                <span className="text-grottoBlue font-semibold">
                  "{groupToDelete?.name}"
                </span>
                ?
              </div>
            </DialogContent>
            <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
              <Button variant="outlined" onClick={() => setGroupToDelete(null)}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleDeleteGroup()}>
                Delete
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </div>
      </AdminLayout>
    </RouteGuardAdmin>
  );
}

const groupTypes = ["speciality", "clinic", "global"];
