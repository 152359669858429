import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { recoverPasswordAPI } from "apis";
import AnimatedLink from "components/common/link";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeLocalData } from "utils/storage";

export function ForgotPassword() {
  const navigate = useNavigate();
  const { user, token } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [credential, setCredentials] = useState({
    email: "",
  });

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    await recoverPasswordAPI({
      email: credential?.email,
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(
            res?.data?.message ||
              "Something went wrong. kindly contact support."
          );
        } else {
          toast?.success("OTP sent to you email.");
          navigate(`/reset-password`);
        }
      })
      .catch((error) => {
        toast.error("Error Recovering password.");
        removeLocalData("@nz__l");
        removeLocalData("@nz__user");
      });
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Layout>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Recover Password
          </h2>
        </div>

        <div className="mt-10 mb-[3rem] sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow-lg sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleRecoverPassword}>
              <TextField
                fullWidth
                label="Email"
                required
                type="email"
                onChange={(e) =>
                  setCredentials({ ...credential, email: e?.target?.value })
                }
              />

              <div>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={Boolean(loading)}
                  type="submit">
                  Recover Password
                </LoadingButton>
              </div>
            </form>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Remember your password?{" "}
            <AnimatedLink
              to="/login"
              underlineColor="turqoise"
              className="font-semibold leading-6 text-turqoise">
              Login
            </AnimatedLink>
          </p>
        </div>
      </div>
    </Layout>
  );
}
