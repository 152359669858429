import React, { useState } from "react";

import CancelSubscriptionModal from "components/modals/cancelSubscription";
import UpdateCardModal from "components/modals/cardDetails/updateCard";

const ModalsContext = React.createContext();

/**
 * This hook can only be used by components under the `ModalsContext` component. Otherwise it will throw.
 */
export const useModalsContext = () => {
  const context = React.useContext(ModalsContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

/**
 * Component used to render components that depend on Modals being available. These components can then
 * `useModalsContext` safely to get stuff without having to null check it.
 */
export const Modals = (props) => {
  const [modal, setModal] = useState(false);

  const toggleModal = (arg) => {
    if (!modal) {
      setModal(arg);
    } else {
      setModal({});
    }
  };

  const toggleUpdateCardDetailsModal = () => {
    if (!modal) {
      setModal("UPDATE_CARD__DETAILS");
    } else {
      setModal(false);
    }
  };

  const toggleCancelSubscriptionModal = (payload) => {
    if (!modal) {
      setModal({ type: "CANCEL_SUBSCRIPTION_MODAL", payload });
    } else {
      setModal(false);
    }
  };

  return (
    <ModalsContext.Provider
      value={{
        toggleUpdateCardDetailsModal,
        toggleCancelSubscriptionModal,
      }}>
      {modal && (
        <>
          {/* Card Modals */}
          {modal === "UPDATE_CARD__DETAILS" && (
            <UpdateCardModal
              open={modal ? true : false}
              handleClose={() => toggleUpdateCardDetailsModal()}
            />
          )}
          {modal?.type === "CANCEL_SUBSCRIPTION_MODAL" && (
            <CancelSubscriptionModal
              open={modal ? true : false}
              handleClose={() => toggleCancelSubscriptionModal()}
              payload={modal?.payload}
            />
          )}
        </>
      )}
      {props.children}
    </ModalsContext.Provider>
  );
};
