import { Button } from "@mui/material";
import { keyframes } from "@emotion/react";
import { forwardRef } from "react";
import styled from "@emotion/styled";

export function TooltipJoy({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}) {
  return (
    <div
      {...tooltipProps}
      className="bg-white text-center overflow-hidden p-4 rounded-lg min-w-[290px] max-w-[420px]">
      <div padding="md">
        {step.title && <h3 className="text-2xl">{step.title}</h3>}
        {step.content && (
          <h3 className="text-xl text-grottoBlue">{step.content}</h3>
        )}
      </div>
      <div className="pt-4">
        <div className="flex justify-between">
          {/* {!isLastStep && ( */}
          <Button variant="text" {...skipProps} id="skip" size="small">
            Skip
          </Button>
          {/* )} */}
          <div className="flex gap-2">
            {index > 0 && (
              <Button {...backProps} variant="outlined" id="back" size="small">
                Back
              </Button>
            )}
            <Button
              {...primaryProps}
              id={continuous ? "next" : "close"}
              size="small">
              {continuous ? "next" : "close"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  55% {
    background-color: red;
    transform: scale(1.2);
  }
`;

const BeaconButton = styled.button`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: "red";
  border: 0;
  // border-radius: 50%;
  border-radius: 10px;
  display: inline-block;
  padding: 0 5px;
  color: "white";
  // height: 3rem;
  // width: 3rem;
`;

export const BeaconComponent = forwardRef((props, ref) => {
  return (
    <BeaconButton ref={ref} {...props}>
      <p className="text-white text-sm">Click Here</p>
    </BeaconButton>
  );
});
