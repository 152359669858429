import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { changePasswordAPI } from "apis";
import { useAuthContext } from "contexts/authContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function ChangePassword() {
  const navigate = useNavigate();
  const { user, handleSessionExpired } = useAuthContext();
  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      credentials?.newPassword1 == credentials?.newPassword2 &&
      credentials?.newPassword1?.length > 7
    ) {
      await changePasswordAPI({
        first_name: user?.first_name,
        last_name: user?.last_name,
        oldPassword: credentials?.oldPassword,
        newPassword: credentials?.newPassword2,
      })
        .then((res) => {
          // console.log(res);
          toast?.success("Password changed successfully.");
          setCredentials({
            oldPassword: "",
            newPassword1: "",
            newPassword2: "",
          });
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status == 401) {
            handleSessionExpired();
          } else {
            toast.error(err?.response?.message || "Error changing password.");
          }
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user?.type != "email") {
      navigate("/settings?tab=account");
    }
  }, [user]);

  return (
    <div>
      <h2 className="text-xl font-semibold leading-7 text-gray-900">
        Change Password
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        Enter old password then new password and press change.
      </p>
      <form onSubmit={handleChangePassword}>
        <div className="mt-6 divide-gray-100 pb-8 border-b border-gray-200 text-sm leading-6">
          <div className="grid grid-cols-5 gap-4 my-2 max-w-[600px] w-full items-center">
            <h2 className="text-base font-semibold col-span-2 leading-7 text-gray-900">
              Current Password:
            </h2>
            <TextField
              fullWidth
              className="col-span-3"
              required
              type="password"
              value={credentials?.oldPassword}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  oldPassword: e?.target?.value,
                })
              }
            />
            <h2 className="text-base font-semibold col-span-2 leading-7 text-gray-900">
              New Password:
            </h2>
            <TextField
              fullWidth
              className="col-span-3"
              required
              type="password"
              value={credentials?.newPassword1}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  newPassword1: e?.target?.value,
                })
              }
              error={
                credentials?.newPassword1?.length != 0 &&
                credentials?.newPassword1?.length < 7
              }
              helperText={
                credentials?.newPassword1?.length != 0 &&
                credentials?.newPassword1?.length < 7 &&
                "Password must be 8 characters long."
              }
            />
            <h2 className="text-base font-semibold col-span-2 leading-7 text-gray-900">
              Re-Enter New Password:
            </h2>
            <TextField
              fullWidth
              className="col-span-3"
              required
              type="password"
              value={credentials?.newPassword2}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  newPassword2: e?.target?.value,
                })
              }
              error={credentials?.newPassword1 != credentials?.newPassword2}
              helperText={
                credentials?.newPassword1 != credentials?.newPassword2 &&
                "Both Passwords should be same."
              }
            />
          </div>
          <div className="flex justify-end max-w-[600px] my-8 w-full">
            <LoadingButton loading={loading} type="submit" variant="contained">
              Change Password
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
}
