import { getCardDetailsAPI } from "apis";
import { toast } from "react-toastify";

const { createSlice } = require("@reduxjs/toolkit");

const userDetailSlice = createSlice({
  name: "userDetails",
  initialState: {
    card: null,
  },
  reducers: {
    setCardDetails(state, action) {
      state.card = action.payload;
    },
  },
});

export const { setCardDetails } = userDetailSlice.actions;
export default userDetailSlice.reducer;

// thunks
export function fetchCardDetails() {
  return async function fetchCardDetailsThunk(dispatch, getState) {
    dispatch(setCardDetails(null));
    await getCardDetailsAPI()
      .then((res) => {
        // console.log(res);
        dispatch(setCardDetails(res?.data?.result[0] || "no details"));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching CardDetails");
      });
  };
}
