import Layout from "fragments/layout/layout";
import { PricingComponent } from "./component/plan";

export function Pricing() {
  return (
    <Layout title="Pricing">
      <PricingComponent />
    </Layout>
  );
}
