import { getSubscription, updateIntroStatus } from "apis";
import { auth, googleAuthProvider } from "config/firebase";
import { signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getLocalData,
  getSessionData,
  removeLocalData,
  setLocalData,
} from "utils/storage";

const AuthContext = React.createContext(null);

/**
 * This hook can only be used by components under the `AppContext` component. Otherwise it will throw.
 */
export const useAuthContext = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

/**
 * Component used to render components that depend on AppContext being available. These components can then
 * `useAppContext` safely to get stuff without having to null check it.
 */
export const AuthState = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [userTier, setUserTier] = useState();

  // Admin
  const [admin, setAdmin] = useState();
  const [admintoken, setAdminToken] = useState();

  const signinWithGoogle = () => {
    return signInWithPopup(auth, googleAuthProvider);
  };

  const handleLogout = () => {
    removeLocalData("@nz__l");
    removeLocalData("@nz__user");
    navigate("/");
    window?.location?.reload();
  };

  const handleSessionExpired = () => {
    toast.error("Session Expired. Kindly login again");
    removeLocalData("@nz__l");
    removeLocalData("@nz__user");
    window?.location?.replace("/session-expired");
    // navigate("/session-expired");
  };

  const checkTier = async () => {
    try {
      const res = await getSubscription();
      // console.log(res);
      if (res?.data?.result) {
        const _tier = res?.data?.result;
        setUserTier(_tier);
        return _tier;
      } else {
        setUserTier(undefined);
        return undefined;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        handleSessionExpired();
      } else {
        toast.error(err?.response?.message || "Error checking user tier.");
      }
      throw new Error(err);
    }
  };

  const handleUpdateIntroStatus = async () => {
    await updateIntroStatus()
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
        } else {
          setLocalData(
            "@nz__user",
            JSON.stringify({
              ...user,
              introDone: true,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Users
  useEffect(() => {
    const _user = getLocalData("@nz__user");
    const _token = getLocalData("@nz__l");
    const _userObj = JSON.parse(_user);

    setUser({
      ..._userObj,
      firstName: _userObj?.first_name,
      lastName: _userObj?.last_name,
    });
    setToken(_token);

    if (_token) {
      checkTier();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Amdin
  useEffect(() => {
    const _admin = getSessionData("@nz__user");
    const _adminToken = getSessionData("@nz__l");
    const _adminObj = JSON.parse(_admin);
    setAdmin({
      ..._adminObj,
      firstName: _adminObj?.first_name,
      lastName: _adminObj?.last_name,
    });

    setAdmin({
      ..._adminObj,
      firstName: _adminObj?.first_name,
      lastName: _adminObj?.last_name,
    });
    setAdminToken(_adminToken);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        admin,
        admintoken,
        userTier,
        setUserTier,
        checkTier,
        handleSessionExpired,
        signinWithGoogle,
        handleLogout,
        handleUpdateIntroStatus,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
