import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { loginAPI, resetPasswordAPI } from "apis";
import AnimatedLink from "components/common/link";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeLocalData } from "utils/storage";

export function ResetPassword() {
  const navigate = useNavigate();
  const { user, token } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    await resetPasswordAPI({
      email: credentials?.email,
      password: credentials?.newPassword1,
      otp: credentials?.otp
    })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "fail") {
          toast?.error(
            res?.data?.message ||
              "Something went wrong. kindly contact support."
          );
        } else {
          toast?.success("Successfully reset password");
          navigate(`/login`);
        }
      })
      .catch((error) => {
        toast.error("Error reset pasword");
        removeLocalData("@nz__l");
        removeLocalData("@nz__user");
      });
      setLoading(false);
    };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Layout>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset Password
          </h2>
        </div>

        <div className="mt-10 mb-[3rem] sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow-lg sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleResetPassword}>
              <TextField
                fullWidth
                required
                label="Email"
                type="email"
                onChange={(e) =>
                  setCredentials({ ...credentials, email: e?.target?.value })
                }
              />
              <TextField
                fullWidth
                label="Enter new password"
                className="col-span-3"
                required
                type="password"
                value={credentials?.newPassword1}
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    newPassword1: e?.target?.value,
                  })
                }
                error={
                  credentials?.newPassword1?.length != 0 &&
                  credentials?.newPassword1?.length < 7
                }
                helperText={
                  credentials?.newPassword1?.length != 0 &&
                  credentials?.newPassword1?.length < 7 &&
                  "Password must be 8 characters long."
                }
              />
              <TextField
                fullWidth
                label="Re-Enter new password"
                className="col-span-3"
                required
                type="password"
                value={credentials?.newPassword2}
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    newPassword2: e?.target?.value,
                  })
                }
                error={credentials?.newPassword1 != credentials?.newPassword2}
                helperText={
                  credentials?.newPassword1 != credentials?.newPassword2 &&
                  "Both Passwords should be same."
                }
              />
              <TextField
                fullWidth
                label="Enter OTP"
                className="col-span-3"
                required
                type="otp"
                value={credentials?.otp}
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    otp: e?.target?.value,
                  })
                }
              />

              <div className="mt-4">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={Boolean(loading)}
                  type="submit">
                  Reset Password
                </LoadingButton>
              </div>
            </form>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Remember your password?{" "}
            <AnimatedLink
              to="/login"
              underlineColor="turqoise"
              className="font-semibold leading-6 text-turqoise">
              Login
            </AnimatedLink>
          </p>
        </div>
      </div>
    </Layout>
  );
}
