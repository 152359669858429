import { Widget } from "@typeform/embed-react";
import Layout from "fragments/layout/layout";
import React from "react";

function Trial() {
  return (
    <Layout>
      <div className="">
        <Widget
          id={"mMIkA0DC"}
          style={widgetContainerStyle}
          medium="NotesZen survey form"
          // hidden={{ foo: "foo value", bar: "bar value" }}
          // transitiveSearchParams={["foo", "bar"]}
          // iframeProps={{ title: "Foo Bar" }}
        />
      </div>
    </Layout>
  );
}

const widgetContainerStyle = {
  // width: 500,
  height: "80vh",
  margin: "20px auto",
};

export default Trial;
