import React from "react";

export const YoutubeEmbed = ({ embedId, width, height, className }) => (
  // <div className="video-responsive">
    <iframe
      className={className}
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  // </div>
);
