import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { loginAPI, socialLogin } from "apis";
import AnimatedLink from "components/common/link";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeLocalData, setLocalData, setSessionData } from "utils/storage";

export function Login() {
  const navigate = useNavigate();
  const { signinWithGoogle, user, token, checkTier } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [credential, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await loginAPI({
      email: credential?.email,
      password: credential?.password,
    })
      .then((res) => {
        // console.log(res);

        if (res?.data?.status == "fail") {
          toast.error(res?.data?.message || "Error login");
          removeLocalData("@nz__l");
          removeLocalData("@nz__user");
        } else {
          const response = res?.data?.result;
          if (response?.user?.role == "admin") {
            setSessionData("@nz__l", response?.token);
            setSessionData("@nz__user", JSON.stringify(response?.user));
            window.location.replace("/admin/templates");
          } else {
            setLocalData("@nz__l", res?.data?.result?.token);
            setLocalData(
              "@nz__user",
              JSON.stringify({
                ...res?.data?.result?.user,
                type: "email",
                photoURL: user?.photoURL,
              })
            );
            window.location.replace("/schedule");
          }

          if (res?.data?.result?.user) {
          } else {
          }
          // window.location.reload();
          // checkTier()
        }
      })
      .catch((error) => {
        toast.error("Error login");
        removeLocalData("@nz__l");
        removeLocalData("@nz__user");
      });
    setLoading(false);
  };

  const handleSocialLogin = async ({ user, type }) => {
    socialLogin({
      email: user?.email,
      uId: user?.uid,
      firstName: user?.displayName?.split(" ")[0] || "",
      lastName: user?.displayName?.split(" ")[1] || "",
      type: type,
    })
      .then((res) => {
        // console.log(res);

        // if (res?.data)

        setLocalData("@nz__l", res?.data?.result?.token);
        setLocalData(
          "@nz__user",
          JSON.stringify({
            ...res?.data?.result?.user,
            type: "google",
            photoURL: user?.photoURL,
          })
        );

        if (res?.data?.result?.user?.survey_done) {
        } else {
          // navigate("/");
        }

        // checkTier()
        window.location.replace("/visit");
      })
      .catch((error) => {
        toast.error("Error login");
        removeLocalData("@nz__l");
        removeLocalData("@nz__user");
      });
  };

  const handleLoginWithGoogle = async () => {
    await signinWithGoogle()
      .then((response) => {
        // console.log(response.user);
        handleSocialLogin({ user: response?.user, type: "google" });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error login");
      });
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Layout>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 mb-[3rem] sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow-lg sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={(e) => handleLogin(e)}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                onChange={(e) =>
                  setCredentials({ ...credential, email: e?.target?.value })
                }
              />
              <TextField
                label="Password"
                type="password"
                onChange={(e) =>
                  setCredentials({ ...credential, password: e?.target?.value })
                }
              />

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <Link to="/forgot-password" className="text-sm leading-6">
                  <p className="font-semibold text-primary cursor-pointer">
                    Forgot password?
                  </p>
                </Link>
              </div>

              <div>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={Boolean(loading)}
                  type="submit">
                  Sign in
                </LoadingButton>
              </div>
            </form>

            <div className="">
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-col-1 sm:grid-cols-1 gap-4">
                {/* <div
                  onClick={() => toast.info("Coming soon")}
                  className="flex w-full items-center cursor-pointer justify-center gap-3 rounded-md p-3 text-white bg-blue-800 text-sm shadow-1">
                  <BiLogoMicrosoft className="text-2xl" />
                  <span className="font-semibold leading-6">Microsoft</span>
                </div> */}
                <div
                  onClick={() => handleLoginWithGoogle()}
                  className="flex w-full items-center cursor-pointer justify-center gap-3 rounded-md p-3 text-whit bg-white text-sm shadow-1">
                  <FcGoogle className="text-2xl" />
                  <span className="font-semibold leading-6">
                    Login With Google
                  </span>
                </div>
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Don't have an account?{" "}
            <AnimatedLink
              to="/signup"
              underlineColor="turqoise"
              className="font-semibold leading-6 text-turqoise">
              Create Account
            </AnimatedLink>
          </p>
        </div>
      </div>
    </Layout>
  );
}
